/* eslint-disable i18next/no-literal-string */
import React, { FC, useEffect, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  BigidBody1,
  BigidCaption,
  BigidColorsV2,
  BigidHeading2,
  BigidHeading5,
  BigidLoader,
  BigidPaper,
} from '@bigid-ui/components';
import { BigidClockIcon, BigidGlobeIcon, BigidIdIcon, BigidScheduleIcon } from '@bigid-ui/icons';

import { AppTheme } from '../../../theme/AppTheme';
import { BigidMeWireframe } from '../../../components';
import { TenantContainer } from '../../../state/tenantContainer';
import { vendorService } from '../../../services/vendor/vendorService';
import { VendorDetailsType } from '../../../services/vendor/types';
import { VendorRequestResolutionForm } from './VendorRequestResolutionForm';
import { VendorRequestView } from '../VendorRequestView/VendorRequestView';
import { AxiosError } from 'axios';
import { getViewByErrorCode } from '../utils';
import { views } from '../consts';
import { ActiveViewType } from '../types';
import { formatDateToLocal } from '../../../utils/dateUtils';
import { AMERICAN_SLASH_DATE_FORMAT } from '../../../constants/dateFormats';

const ConsumerDetailField: FC<{ label: string; value: string }> = ({ label, value }) => (
  <Box sx={{ wordBreak: 'break-word', flex: '0 auto', width: { sm: '50%', md: '33%' }, pr: '8px' }}>
    <BigidCaption fontSize="small" fontWeight="bold">
      {label}
    </BigidCaption>
    <BigidBody1>{value}</BigidBody1>
  </Box>
);

export const VendorRequestResolution = () => {
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const { vendorRequestId, secret } = useParams();
  const [requestDetails, setRequestDetails] = useState<VendorDetailsType>();
  const [status, setStatus] = useState<ActiveViewType | undefined>();
  const [loading, setLoading] = useState(true);

  const consumerDetailsList = requestDetails?.userRequest?.attributes;

  useEffect(() => {
    const initLoad = async () => {
      try {
        setLoading(true);
        await loadTenantSettings({});
        if (vendorRequestId && secret) {
          const data = await vendorService.getVendorDetails(vendorRequestId, { secret });

          if (data.hasResponse && data.responseRequired) {
            setStatus('resolutionSent');
            setRequestDetails(data);
          } else {
            setRequestDetails(data);
          }
        }
      } catch (error) {
        const code = (error as AxiosError<{ errorCode: number }>)?.response?.data?.errorCode;
        setStatus(code ? getViewByErrorCode(code) : 'failed');
      } finally {
        setLoading(false);
      }
    };
    initLoad();
  }, [vendorRequestId, secret, loadTenantSettings]);

  if (loading) {
    return (
      <AppTheme>
        <BigidLoader />
      </AppTheme>
    );
  }

  if (status) {
    return (
      <AppTheme>
        <BigidMeWireframe {...tenant} hideLanguageControls pageTitle="A deletion request needs your attention">
          <Stack alignItems="center" justifyContent="center" height="100%">
            <VendorRequestView
              title={views[status].title}
              description={
                (requestDetails?.responseTimestamp &&
                  views[status].generateDescription?.(
                    requestDetails?.responseEmail,
                    formatDateToLocal(requestDetails?.responseTimestamp, AMERICAN_SLASH_DATE_FORMAT),
                  )) ||
                views[status].description
              }
              icon={views[status].icon}
            />
          </Stack>
        </BigidMeWireframe>
      </AppTheme>
    );
  }

  return (
    <AppTheme>
      <BigidMeWireframe {...tenant} hideLanguageControls pageTitle="A deletion request needs your attention">
        <Stack alignItems="center" justifyContent="center" height="100%" pt={8} pb={12}>
          <Stack gap={2} maxWidth="832px" px={2} width="100%">
            <BigidHeading2 variantMapping={{ h2: 'h1' }} mb={3} textAlign="center">
              A deletion request awaiting your attention
            </BigidHeading2>
            <BigidPaper
              paperProps={{
                sx: {
                  width: '100%',
                  maxHeight: { md: '95px' },
                  padding: '24px 40px',
                  borderRadius: 2,
                },
              }}
            >
              {requestDetails && (
                <Stack direction={{ md: 'row' }} justifyContent="space-between" gap={{ xs: 1, md: 5 }}>
                  <div>
                    <Stack direction="row" alignItems="center">
                      <BigidIdIcon size="small" />
                      <BigidCaption fontSize="small" fontWeight="bold" ml={0.5}>
                        Request ID
                      </BigidCaption>
                    </Stack>
                    <BigidBody1>{requestDetails.userRequest.requestId}</BigidBody1>
                  </div>
                  <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />
                  <div>
                    <Stack direction="row" alignItems="center">
                      <BigidScheduleIcon size="small" />
                      <BigidCaption fontSize="small" fontWeight="bold" ml={0.5}>
                        Create date
                      </BigidCaption>
                    </Stack>
                    <BigidBody1>
                      {formatDateToLocal(requestDetails.userRequest.processingStartDate, AMERICAN_SLASH_DATE_FORMAT)}
                    </BigidBody1>
                  </div>
                  <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />
                  <div>
                    <Stack direction="row" alignItems="center">
                      <BigidClockIcon size="small" />
                      <BigidCaption fontSize="small" fontWeight="bold" ml={0.5}>
                        Due in
                      </BigidCaption>
                    </Stack>
                    <BigidBody1>{requestDetails.userRequest.dueDays} days</BigidBody1>
                  </div>
                  <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />
                  <div>
                    <Stack direction="row" alignItems="center">
                      <BigidGlobeIcon size="small" />
                      <BigidCaption fontSize="small" fontWeight="bold" ml={0.5}>
                        Assigned regulation
                      </BigidCaption>
                    </Stack>
                    <BigidBody1>{requestDetails.userRequest.regulation}</BigidBody1>
                  </div>
                </Stack>
              )}
            </BigidPaper>
            <BigidPaper
              paperProps={{
                sx: {
                  width: '100%',
                  padding: '24px 40px',
                  borderRadius: 2,
                },
              }}
            >
              <BigidPaper
                hasShadow={false}
                paperProps={{
                  sx: {
                    width: '100%',
                    padding: '24px 24px',
                    borderRadius: 2,
                    backgroundColor: BigidColorsV2.gray[150],
                    '&&': { flexGrow: 0 },
                    mb: '24px',
                  },
                }}
              >
                <BigidHeading5 variantMapping={{ h5: 'h2' }} fontWeight="bold" mb={2}>
                  Consumer details
                </BigidHeading5>
                <Stack direction={{ sm: 'row' }} flexWrap="wrap">
                  {consumerDetailsList?.map(attr => (
                    <ConsumerDetailField key={attr.name} label={attr.label} value={attr.value} />
                  ))}
                </Stack>
              </BigidPaper>
              {requestDetails?.responseRequired && (
                <VendorRequestResolutionForm
                  resolutions={requestDetails.responseResolutions}
                  setStatus={setStatus}
                  onSuccess={data => {
                    setRequestDetails(data);
                    setStatus('resolutionSent');
                  }}
                />
              )}
            </BigidPaper>
          </Stack>
        </Stack>
      </BigidMeWireframe>
    </AppTheme>
  );
};
