import React, { FC, ReactNode } from 'react';
import { BigidBody1, PrimaryButton } from '@bigid-ui/components';
import { useForm } from 'react-hook-form';
import { Box, Divider } from '@mui/material';

import { FormFieldsSection } from '../FormFieldsSection';
import { DynamicFormContainer } from '../../DynamicFormContainer';
import { FormSectionVariantType, NormalizedFormFieldType, LayoutType } from '../../../types/common';
import { DynamicFormReducerActionType } from '../../state';
import { LanguageContainer } from '../../../../state/languageContainer';
import { normalizeFields } from '../../utils';
import { Captcha } from '../Captcha';
import { parseHTML } from '../../../../utils';

type RequestDetailsSectionFormComponentPropsType = {
  agentFields: NormalizedFormFieldType[];
  dataSubjectFields: NormalizedFormFieldType[];
  dataSubjectHeader: ReactNode;

  submitButtonText?: string;
  onChange?: (values: Record<string, string>) => void;
  onSubmit: (values: Record<string, string>) => void;
  hideSubmit?: boolean;
  disableSubmit?: boolean;
  captchaShown?: boolean;
  layout: LayoutType;
};

const RequestDetailsSectionFormComponent: FC<RequestDetailsSectionFormComponentPropsType> = ({
  layout,
  agentFields,
  dataSubjectFields,
  onChange,
  onSubmit,
  submitButtonText = 'Next',
  hideSubmit,
  disableSubmit,
  dataSubjectHeader,
  captchaShown,
}) => {
  const { control, handleSubmit, getValues } = useForm();

  const handleFieldsUpdate = () => {
    const values = getValues();
    onChange?.(values);
  };

  return (
    <>
      <FormFieldsSection
        compact={layout === 'compact'}
        control={control}
        fields={agentFields}
        onChange={handleFieldsUpdate}
      />
      {dataSubjectHeader}
      <FormFieldsSection
        compact={layout === 'compact'}
        control={control}
        fields={dataSubjectFields}
        onChange={handleFieldsUpdate}
      />
      {captchaShown && <Captcha />}
      {!hideSubmit && (
        <Box sx={{ mb: 1, mr: 3 }} textAlign="right">
          <PrimaryButton
            size="medium"
            onClick={handleSubmit(onSubmit)}
            disabled={disableSubmit}
            text={submitButtonText}
          />
        </Box>
      )}
    </>
  );
};

type RequestSectionFormPropsType = {
  signInUrl?: string;
  submitButtonText?: string;
  captchaShown?: boolean;
  onUpdate: (values: Record<string, string>) => void;
  onSubmit?: (values: Record<string, string>) => void;
};

export const RequestDetailsSectionForm: FC<RequestSectionFormPropsType> = ({
  submitButtonText = 'Next',
  signInUrl,
  captchaShown,
  onUpdate,
  onSubmit,
}) => {
  const {
    dispatch,
    state: { forms, normalizedSections, layout },
  } = DynamicFormContainer.useContainer();

  const { language, BigIdMeTranslate } = LanguageContainer.useContainer();

  if (
    !forms ||
    (!normalizedSections?.[FormSectionVariantType.DATA_SUBJECT] &&
      !normalizedSections?.[FormSectionVariantType.AUTHORIZED_AGENT])
  ) {
    return null;
  }

  const agentSectionTitle = normalizedSections[FormSectionVariantType.AUTHORIZED_AGENT].translations[language]?.title;
  const agentSectionDescription =
    normalizedSections[FormSectionVariantType.AUTHORIZED_AGENT].translations[language]?.description;
  const dataSubjectSectionTitle = normalizedSections[FormSectionVariantType.DATA_SUBJECT].translations[language]?.title;
  const dataSubjectSectionDescription =
    normalizedSections[FormSectionVariantType.DATA_SUBJECT].translations[language]?.description;

  const agentFields = normalizedSections[FormSectionVariantType.AUTHORIZED_AGENT].fields.map(field =>
    normalizeFields({
      field,
      locale: language,
      defaultValue: forms?.[field.name],
      translate: BigIdMeTranslate,
    }),
  );

  const dataSubjectFields = normalizedSections[FormSectionVariantType.DATA_SUBJECT].fields.map(field =>
    normalizeFields({
      field,
      locale: language,
      defaultValue: forms?.[field.name],
      translate: BigIdMeTranslate,
    }),
  );

  const handleSubmit = (values: Record<string, string>) => {
    dispatch({
      type: DynamicFormReducerActionType.SET_FORM_VALUES,
      payload: {
        values,
      },
    });
    onSubmit?.(values);
  };

  return (
    <>
      <Box p="24px 24px 0 24px">
        <BigidBody1 fontWeight="bold">{agentSectionTitle}</BigidBody1>
        {agentSectionDescription && <BigidBody1 size="small">{parseHTML(agentSectionDescription)}</BigidBody1>}
        <Divider sx={{ mt: '12px' }} />
      </Box>
      <RequestDetailsSectionFormComponent
        layout={layout}
        agentFields={agentFields}
        dataSubjectFields={dataSubjectFields}
        onChange={values => onUpdate(values)}
        onSubmit={handleSubmit}
        submitButtonText={submitButtonText}
        hideSubmit={!onSubmit}
        captchaShown={captchaShown}
        dataSubjectHeader={
          <Box p="24px 24px 0 24px">
            <BigidBody1 fontWeight="bold">
              {dataSubjectSectionTitle}
              {signInUrl && (
                <a href={signInUrl} style={{ marginLeft: 0.5 }}>
                  {BigIdMeTranslate('consumer_web_submit_sign-in-link')}
                </a>
              )}
            </BigidBody1>
            {dataSubjectSectionDescription && (
              <BigidBody1 size="small">{parseHTML(dataSubjectSectionDescription)}</BigidBody1>
            )}
            <Divider sx={{ mt: '12px' }} />
          </Box>
        }
      />
    </>
  );
};
