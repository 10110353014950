import { DynamicFormContainer } from '../DynamicFormContainer';
import { DynamicFormReducerActionType } from '../state';

export const useFormValues = () => {
  const {
    dispatch,
    state: { forms },
  } = DynamicFormContainer.useContainer();

  const clear = (currentFieldName: string) => {
    if (!forms) {
      return;
    }

    const fieldNames = Object.keys(forms);
    const currentFieldNamIndex = fieldNames.indexOf(currentFieldName);

    if (currentFieldNamIndex === -1) {
      return;
    }

    dispatch({
      type: DynamicFormReducerActionType.SET_FORM_VALUES,
      payload: {
        values: fieldNames.slice(0, currentFieldNamIndex + 1).reduce(
          (acc, fieldName) => ({
            ...acc,
            [fieldName]: forms[fieldName],
          }),
          {} as Record<string, string>,
        ),
      },
    });
  };

  return { clear };
};
