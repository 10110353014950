import React, { ReactNode, FC } from 'react';
import { Box } from '@mui/material';
import { PrimaryButton } from '@bigid-ui/components';

import { BigidMeThumbnail } from '../../../../../../components';
import { BigidMeIconView } from '../../../../../../assets/icons/BigidMeIconView';
import { NormalizedFormFieldOption, RequestorTypeType, RequestVariantType } from '../../../../../types/common';
import { BigidMeIconUpdate } from '../../../../../../assets/icons/BigidMeIconUpdate';
import { BigidMeIconDelete } from '../../../../../../assets/icons/BigidMeIconDelete';
import { BigidMeIconPreferences } from '../../../../../../assets/icons/BigidMeIconPreferences';
import { BigidMeIconOptOut } from '../../../../../../assets/icons/BigidMeIconOptOut';
import { BigidMeIconAppeal } from '../../../../../../assets/icons/BigidMeIconAppeal';
import { BigidMeIconRequestorTypeSelf } from '../../../../../../assets/icons/BigidMeIconRequestorTypeSelf';
import { BigidMeIconRequestorTypeAuthorizedAgent } from '../../../../../../assets/icons/BigidMeIconRequestorTypeAgent';
import { LanguageContainer } from '../../../../../../state/languageContainer';

const thumbnailIconsMap = new Map<string, ReactNode>([
  [RequestVariantType.VIEW, <BigidMeIconView key={'view'} />],
  [RequestVariantType.UPDATE, <BigidMeIconUpdate key={'update'} />],
  [RequestVariantType.DELETE, <BigidMeIconDelete key={'delete'} />],
  [RequestVariantType.PREFERENCES, <BigidMeIconPreferences key={'preferences'} />],
  [RequestVariantType.OPT_OUT, <BigidMeIconOptOut key={'optOut'} />],
  [RequestVariantType.APPEAL, <BigidMeIconAppeal key={'appeal'} />],
  [RequestorTypeType.SELF, <BigidMeIconRequestorTypeSelf key={'self'} />],
  [RequestorTypeType.AUTHORIZED_AGENT, <BigidMeIconRequestorTypeAuthorizedAgent key={'authorizedAgent'} />],
  ['Default', <BigidMeIconView key={'default'} />],
]);

type RequestTypeFieldPropsType = {
  options: NormalizedFormFieldOption[];
  value?: string;
  isDisabled?: boolean;
  onSelect: (value: string) => void;
};

export const RequestTypeField: FC<RequestTypeFieldPropsType> = ({ options, value, isDisabled, onSelect }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {options.map(option => (
        <BigidMeThumbnail
          key={option.id}
          title={option.displayValue}
          tooltip={option.description || ''}
          icon={thumbnailIconsMap.get(option.value) || thumbnailIconsMap.get('default')}
          onClick={() => {
            onSelect(option.value);
            return Promise.resolve();
          }}
          selected={value === option.value}
          id={option.id}
          testId={option.value}
          bi={{ eventType: `thumbnail_${option.value}` }}
          button={
            <PrimaryButton
              size="medium"
              onClick={e => {
                onSelect(option.value);
                (e as React.MouseEvent<HTMLButtonElement>)?.currentTarget.blur();
                return Promise.resolve();
              }}
              dataAid={`thumbnail-${option.value}-select`}
              text={BigIdMeTranslate('consumer_web_submit_select')}
              bi={{ eventType: `thumbnail_${option.value}` }}
              disabled={isDisabled && value !== option.value}
            />
          }
        />
      ))}
    </Box>
  );
};
