import React, { FC, Fragment, MutableRefObject, useMemo } from 'react';
import { Divider, Typography } from '@mui/material';
import cx from 'classnames';
import { BigidMeCaptcha } from '../../components/BigidMeCaptcha';
import { NotificationPanel } from '../../components/NotificationPanel';
import {
  CountriesOptionsType,
  FieldControlType,
  FieldDefinition,
  FieldsType,
  FieldValidation,
  FormFieldType,
  GlobalTexts,
  RequestDefinition,
  UserFilesType,
  PhoneType,
} from '../../services';
import { e2e } from '../../utils';
import { ReactComponent as BigidMeIconLock } from '../../assets/icons/lock.svg';
import { LanguageContainer } from '../../state/languageContainer';
import { TenantContainer } from '../../state/tenantContainer';
import { helperTexts } from '../../constants/fieldsLocalizationKeys';
import { SignInType } from '../../services/sign-in/types';
import { UserProfile } from '../../services/userprofile/types';

import { useStyles } from './SubmitRequestStyles';
import { FieldValuesType, SetTouchedFieldsCallback } from '../../utils/submitFields';
import { useUserDetails } from './hooks/useUserDetails';
import { getFieldDefinitionType, groupFieldDefinitions } from '../../utils/helpers';
import { parseHTML } from '../../utils';
import { MeButton } from '../../components';
import { FieldVerificationsContainer } from './FieldVerifications';
import { FieldVerificationsDialog } from './FieldVerifications';
import { BigidApplyIcon } from '@bigid-ui/icons';
import { BigidColorsV2 } from '@bigid-ui/components';

type UserDetailsPropsType = {
  fieldDefinitions: FieldDefinition[];
  signIn?: SignInType;
  tagRef: MutableRefObject<Record<string, null | HTMLElement>>;
  validation?: FieldValidation;
  setValidation: (validation: FieldValidation) => void;
  fieldValues: FieldValuesType;
  setFieldValues: (fieldValues: FieldValuesType) => void;
  countriesOptions: CountriesOptionsType[];
  userProfiles: UserProfile[];
  touchedFields: Record<string, boolean>;
  setTouchedFields: (callback: SetTouchedFieldsCallback) => void;
  selectedRequestDefinition: RequestDefinition | undefined;
  resetSelect?: boolean;
  userFiles: UserFilesType[];
  setUserFiles: React.Dispatch<React.SetStateAction<UserFilesType[]>>;
  setCaptchaToken: React.Dispatch<React.SetStateAction<string | null>>;
  withAuthorizedAgent: boolean;
};

const TEXT_AREA_ROWS = 4;

export const UserDetails: FC<UserDetailsPropsType> = ({
  fieldDefinitions,
  signIn,
  validation,
  tagRef,
  setValidation,
  fieldValues,
  setFieldValues,
  countriesOptions,
  userProfiles,
  touchedFields,
  setTouchedFields,
  selectedRequestDefinition,
  resetSelect,
  userFiles,
  setUserFiles,
  setCaptchaToken,
  withAuthorizedAgent,
}) => {
  const classes = useStyles();
  const tenant = TenantContainer.useContainer().tenant;
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const canSignIn = signIn?.status === 'SIGNED_OUT' && signIn?.signInUrl;

  const { validateFields, fieldTypes } = useUserDetails({
    countriesOptions,
    userProfiles,
    fieldValues,
    fieldDefinitions,
    selectedRequestDefinition,
  });

  const handleFieldTouched = (id: string) => {
    setTouchedFields((prev: Record<string, boolean>) => ({ ...prev, [id]: true }));
  };

  const handleFieldChange = (id: string, value: string | UserFilesType | PhoneType): void => {
    let values = { ...fieldValues, [id]: value };

    if (id === FieldsType.USER_COUNTRY) {
      // clean USER_COUNTRY_DIVISION if USER_COUNTRY was changed
      values = { ...fieldValues, [id]: value, [FieldsType.USER_COUNTRY_DIVISION]: '' };
    } else if (id === FieldsType.USER_PHOTO) {
      if (!value) {
        // cleaing photo files on remove
        setUserFiles([]);
      } else {
        // setting photo files on download
        setUserFiles([...userFiles, value as UserFilesType]);
      }
    }
    setFieldValues(values);
    setValidation(validateFields(values));
  };

  const { verifyField, fieldVerificationList, resetVerificationForField, initVerifyLoading } =
    FieldVerificationsContainer.useContainer();

  const renderFields = (fields: FieldDefinition[]) =>
    fields.map(field => {
      const translation = field.translations[language];
      if (!translation) {
        return null;
      }
      const fieldId = getFieldDefinitionType(field, fieldTypes);
      const InputComponent = fieldTypes[fieldId].component;
      const label =
        translation?.label || BigIdMeTranslate(`consumer_web_submit_field-${field.id.toString().toLowerCase()}_label`);

      const helper =
        translation?.tooltip ||
        // BME-1192: dynamic helper for picked user type: User/Customer/Consumer/ ..etc
        (fieldId === FieldsType.USER_TYPE &&
          fieldValues[FieldsType.USER_TYPE] &&
          BigIdMeTranslate(
            `consumer_web_general_${fieldValues[FieldsType.USER_TYPE]
              .toString()
              .toLowerCase()
              .replace(/\s/g, '-')}_helper`,
          )) ||
        BigIdMeTranslate(`consumer_web_submit_field-${field.id.toString().toLowerCase()}_helper`) ||
        BigIdMeTranslate(helperTexts[field.id]);

      const placeholder = translation?.hint || '';

      const error = touchedFields[field.id] && validation?.messages[field.id] ? validation.messages[field.id] : '';
      const options = fieldTypes[fieldId].optionsSource(field) || [];
      const onChange = (e: string | UserFilesType | PhoneType) => {
        handleFieldChange(field.id, e);
        handleFieldTouched(field.id);
        field.verificationRequired && resetVerificationForField(field.id, e as string);
      };

      const fieldStyles = cx(classes.wrapperFlex, classes.inputField, {
        [classes.fullWidthField]: fieldTypes[fieldId].isFullWidth,
      });

      const isDisabled =
        field.fieldControl === FieldControlType.READONLY && !!field.translations[language]?.defaultValue;

      const multiline = field.id === FieldsType.CORRECTION_FIELD || field.fieldType === FormFieldType.MULTI_LINE;
      const rows = multiline ? TEXT_AREA_ROWS : undefined;
      const defaultValue =
        field.id === FieldsType.USER_COUNTRY_DIVISION
          ? options.find(
              item =>
                item?.label === field.translations[language]?.defaultValue ||
                item?.value === field.translations[language]?.defaultValue,
            )?.value
          : field.translations[language]?.defaultValue;

      return (
        field.fieldControl !== FieldControlType.HIDDEN && (
          <Fragment key={field.id}>
            {fieldId === FieldsType.USER_PHOTO && <div style={{ flexBasis: '100%' }}></div>}
            <div
              className={cx(fieldStyles, {
                [classes.hiddenField]: !fieldTypes[fieldId].isVisible({ fieldValues, field, fieldDefinitions }),
              })}
              id={`form-${field.id}`}
            >
              <div
                className={cx(classes.wrapperField, {
                  [classes.photoUploadFieldWrapper]: fieldId === FieldsType.USER_PHOTO,
                  [classes.wrapperFieldWithFlex]: field.verificationRequired,
                })}
                ref={el => (tagRef.current[field.id] = error ? el : null)}
              >
                <InputComponent
                  isDisabled={
                    isDisabled && !!options.find(({ value }) => value === field.translations[language]?.defaultValue)
                  }
                  disabled={isDisabled}
                  id={field.id}
                  label={label}
                  required={fieldTypes[fieldId].isRequired({ field, fieldValues, fieldDefinitions })}
                  placeholder={placeholder}
                  error={
                    error ||
                    (field.verificationRequired && fieldVerificationList?.[field.id].status === 'FAILED'
                      ? fieldVerificationList?.[field.id].error
                      : undefined)
                  }
                  onChange={onChange}
                  options={options}
                  isSearchable={true}
                  defaultValue={defaultValue}
                  key={resetSelect && !!options.length ? field.translations[language]?.defaultValue : undefined}
                  tooltip={translation?.tooltip}
                  withSmsValidation={tenant.smsValidationOn && !withAuthorizedAgent}
                  multiline={multiline}
                  rows={rows}
                  inputFormat={field.dateFormat}
                  helper={helper}
                  {...e2e(`field_${field.id}`)}
                />
                {field.verificationRequired && fieldVerificationList?.[field.id].status !== 'SUCCEEDED' && (
                  <div className={classes.verifyButtonWrapper}>
                    <MeButton
                      type="primary"
                      onClick={() => verifyField({ fieldName: field.id, fieldLabel: translation?.label, fieldValues })}
                      bi={{ eventType: 'verify' }}
                      isDisabled={!fieldValues[field.id] || initVerifyLoading}
                      classes={classes.verifyButton}
                    >
                      {BigIdMeTranslate('consumer_web_verify')}
                    </MeButton>
                  </div>
                )}
                {field.verificationRequired && fieldVerificationList?.[field.id].status === 'SUCCEEDED' && (
                  <div className={classes.successVerified}>
                    <BigidApplyIcon color={BigidColorsV2.green[700]} />
                    {BigIdMeTranslate('consumer_web_verified')}
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )
      );
    });

  const topDisclaimer = selectedRequestDefinition?.texts?.find(
    text => text.name === GlobalTexts.DATA_STORING_TOP_DISCLAIMER,
  );
  const topDisclaimerAdditionalData = topDisclaimer?.disclaimerAdditionalData;

  const bottomDisclaimer = selectedRequestDefinition?.texts?.find(
    text => text.name === GlobalTexts.DATA_STORING_BOTTOM_DISCLAIMER,
  );
  const bottomDisclaimerAdditionalData = bottomDisclaimer?.disclaimerAdditionalData;

  const fieldDefinitionGroups = useMemo(
    () => groupFieldDefinitions(fieldDefinitions, fieldTypes),
    [fieldDefinitions, fieldTypes],
  );

  return (
    <div>
      <div className={classes.displayOnDesktop}>
        <div className={classes.contactDetails}>
          <Typography variant="h4" color="textPrimary">
            <b>{BigIdMeTranslate('consumer_web_submit_contact-details')}</b>
          </Typography>
          {canSignIn && <a href={signIn?.signInUrl}>{BigIdMeTranslate('consumer_web_submit_sign-in-link')}</a>}
        </div>
        <Divider />
      </div>

      {topDisclaimer?.enabled && (
        <div className={classes.notification}>
          <NotificationPanel
            text={parseHTML(topDisclaimer.translations[language]?.value || '')}
            Icon={topDisclaimerAdditionalData?.useIcon ? BigidMeIconLock : undefined}
            transparentBackground={!topDisclaimerAdditionalData?.useBlueBackground}
            testId="topDisclaimer"
          />
        </div>
      )}

      {fieldDefinitionGroups.map((fieldGroup, index) => (
        <div key={index} className={classes.fieldsContainer}>
          {renderFields(fieldGroup)}
        </div>
      ))}
      {bottomDisclaimer?.enabled && (
        <div className={classes.notification}>
          <NotificationPanel
            text={parseHTML(bottomDisclaimer.translations[language]?.value || '')}
            Icon={bottomDisclaimerAdditionalData?.useIcon ? BigidMeIconLock : undefined}
            transparentBackground={!bottomDisclaimerAdditionalData?.useBlueBackground}
            testId="bottomDisclaimer"
          />
        </div>
      )}
      {tenant.captchaOn && <BigidMeCaptcha onClick={setCaptchaToken} isPreviewPP={tenant.isPreviewPP} />}
      <FieldVerificationsDialog />
    </div>
  );
};
