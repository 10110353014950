import React, { FC } from 'react';
import { FormControlLabel } from '@mui/material';
import { BigidBody1, BigidColorsV2, BigidSwitch } from '@bigid-ui/components';
import { e2e, parseHTML } from '../../../../utils';
import { LanguageType } from '../../../../state/languageContainer';
import { PreferenceItemType } from '../../../types/common';
import { StyledRichText } from '../../../../components';

type TogglePropsType = {
  id: number;
  locale: LanguageType;
  items: PreferenceItemType[];
  onChange: (fieldId: number, itemId: number, checked: boolean, refresh?: boolean) => void;
};

export const ToggleField: FC<TogglePropsType> = ({ id, locale, items, onChange }) => {
  const handleChange =
    (itemId: number, hasAffectingRule: boolean) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      onChange(id, itemId, checked, hasAffectingRule);
  return (
    <>
      {items.map(({ id: itemId, translations, selected, hasAffectingRule }, itemIndex) => (
        <FormControlLabel
          key={itemId}
          id={id?.toString()}
          value={itemId}
          labelPlacement="start"
          sx={{
            width: '100%',
            m: 0,
            py: '16px',
            justifyContent: 'space-between',
          }}
          control={
            <div>
              <BigidSwitch
                key={itemId}
                id={itemId?.toString()}
                checked={selected}
                onChange={handleChange(itemId, hasAffectingRule)}
                aria-labelledby={`toggle-label-${id}`}
                {...e2e(`toggle_${itemIndex}`)}
              />
            </div>
          }
          label={
            <>
              <BigidBody1 id={`${id}-label`}>{translations[locale]?.label}</BigidBody1>
              {translations[locale]?.description && (
                <BigidBody1 color={BigidColorsV2.gray[500]}>
                  <StyledRichText>{parseHTML(translations[locale].description!)}</StyledRichText>
                </BigidBody1>
              )}
            </>
          }
        />
      ))}
    </>
  );
};
