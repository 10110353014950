import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/styles';

export const StyledRichText = styled('div')({
  '& table, th, td': {
    border: `1px solid ${BigidColorsV2.gray[400]}`,
    padding: 8,
    textAlign: 'center',
    wordWrap: 'break-word',
  },
});
