import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import { FieldDefinition, FieldValue, requestService } from '../../../services';
import { LanguageContainer } from '../../../state/languageContainer';
import { useParams } from 'react-router-dom';

type StatusType = 'SUCCEEDED' | 'IN_PROGRESS' | 'FAILED';

type FieldVerificationType = {
  id?: string;
  token?: string;
  verificationId?: string;
  status?: StatusType;
  verifiedValue?: string;
  error?: string;
  label?: string;
};

const useFieldVerificationsContainer = () => {
  const [state, setState] = useState<Record<string, FieldVerificationType> | null>(null);
  const [fieldVerifying, setFieldVerifying] = useState<string>();
  const [token, setToken] = useState<string>();
  const [initVerifyLoading, setInitVerifyLoading] = useState(false);

  const { publishName: siteName = '' } = useParams();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode, language } = LanguageContainer.useContainer();

  const setFieldsToVerify = useCallback((fields: FieldDefinition[]) => {
    setState(
      fields.reduce(
        (acc, field) =>
          field.verificationRequired
            ? {
                ...acc,
                [field.id]: {
                  id: field.id,
                  verificationId: '',
                },
              }
            : acc,
        {},
      ),
    );
  }, []);

  const setVerificationField = useCallback(
    ({
      id,
      fieldName,
      fieldValue,
      status,
      error,
      label,
    }: {
      id: string;
      fieldName: string;
      fieldValue?: string;
      status?: StatusType;
      error?: string;
      label?: string;
    }) => {
      setState(prevState => ({
        ...prevState,
        [fieldName]: {
          ...(prevState?.[fieldName] || {}),
          id: fieldName,
          label,
          verificationId: id,
          status,
          verifiedValue: fieldValue,
          error,
        },
      }));
    },
    [],
  );

  const setStatus = useCallback(
    (value: StatusType) => {
      fieldVerifying &&
        setState(prevState => ({
          ...prevState,
          [fieldVerifying]: {
            ...(prevState?.[fieldVerifying] || {}),
            status: value,
          },
        }));
    },
    [fieldVerifying],
  );

  const handleStatus = useCallback(
    (value: StatusType, resolution?: string) => {
      if (value === 'SUCCEEDED') {
        setToken('');
        setFieldVerifying('');

        setStatus(value);
      } else if (value === 'FAILED') {
        setToken('');
        setFieldVerifying('');

        const errorCode =
          resolution === 'Timed out'
            ? 'consumer_web_field_verification_code_expired'
            : 'consumer_web_field_verification_wrong_code';

        fieldVerifying &&
          setState(prevState => ({
            ...prevState,
            [fieldVerifying]: {
              ...(prevState?.[fieldVerifying] || {}),
              status: value,
              error: BigIdMeTranslate(errorCode),
            },
          }));
      } else {
        setStatus(value);
      }
    },
    [fieldVerifying, setStatus],
  );

  const resetVerificationForField = useCallback(
    (fieldName: string, updatedFieldValue: string) => {
      if (!state?.[fieldName]) {
        return;
      }

      if (state?.[fieldName]?.verifiedValue !== updatedFieldValue) {
        setVerificationField({ id: '', fieldName });
      }
    },
    [state],
  );

  const verifyField = useCallback(
    async ({
      fieldName,
      fieldLabel = '',
      fieldValues,
    }: {
      fieldName: string;
      fieldLabel?: string;
      fieldValues: Record<string, FieldValue>;
    }) => {
      try {
        if (typeof fieldValues[fieldName] !== 'string') {
          return;
        }
        setInitVerifyLoading(true);
        const { token, verificationId, status } = await requestService.sendOptFieldVerifications({
          fieldValue: fieldValues[fieldName] as string,
          fieldName,
          data: Object.keys(fieldValues).reduce((acc, key) => {
            const currentValue = fieldValues[key];
            if (typeof currentValue === 'string') {
              return {
                ...acc,
                [key]: currentValue,
              };
            }
            if ('phone' in currentValue) {
              return {
                ...acc,
                [key]: currentValue.phone,
              };
            }
            if ('uploadId' in currentValue) {
              return {
                ...acc,
                [key]: currentValue.uploadId,
              };
            }
            return acc;
          }, {}),
          siteName,
          locale: language,
        });

        if (status === 'FAILED') {
          setVerificationField({
            id: verificationId,
            fieldName,
            fieldValue: fieldValues[fieldName] as string,
            status,
            label: fieldLabel,
            error:
              status === 'FAILED'
                ? (BigIdMeTranslateWithNode('consumer_web_field_verification_wrong_id', [
                    {
                      key: 'field_name',
                      value: fieldLabel,
                    },
                  ]) as string)
                : undefined,
          });
        } else {
          setFieldVerifying(fieldName);
          setToken(token);
          setVerificationField({
            id: verificationId,
            fieldName,
            fieldValue: fieldValues[fieldName] as string,
            status,
            label: fieldLabel,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setInitVerifyLoading(false);
      }
    },
    [language],
  );

  const checkFieldVerification = useCallback(async () => {
    if (!state || !fieldVerifying || !state[fieldVerifying]) {
      return;
    }
    if (!state[fieldVerifying].status) {
      handleStatus('IN_PROGRESS');
    }
    const data = await requestService.checkOptFieldVerifications(state[fieldVerifying].verificationId || '');
    handleStatus(data.status, data.resolution);
  }, [fieldVerifying, state]);

  return {
    setFieldsToVerify,
    fieldsNotVerified: state !== null && Object.values(state).some(data => data.status !== 'SUCCEEDED'),
    openVerifyDialog: !!fieldVerifying,
    fieldVerifying,
    token,
    verifyField,
    fieldVerificationList: state,
    resetVerificationForField,
    initVerifyLoading,
    checkFieldVerification,
  };
};

export const FieldVerificationsContainer = createContainer(useFieldVerificationsContainer);
