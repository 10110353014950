import { experimental_extendTheme as extendTheme, responsiveFontSizes, Theme } from '@mui/material';
import { BigidColorSchemesVars, commonTheme } from '@bigid-ui/components';
import { BigidMeColorSchemesVars, BigidMeSchemeCustomVars } from './BigidMeColorSchemes';
import { BigidMeColors } from './BigidMeColors';
import { makeIllustrationColor } from '../../utils';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const SCREEN_SIZE = {
  MEDIUM: 1024,
  SMALL: 600,
};

export const createBigidMeTheme = ({
  isMobileMode,
  isRtl,
  colors,
  fontFamily,
  customFont,
}: {
  isMobileMode?: boolean;
  isRtl?: boolean;
  colors?: BigidMeSchemeCustomVars;
  fontFamily?: string;
  customFont?: boolean;
}) =>
  extendTheme({
    ...commonTheme,
    ...responsiveFontSizes(
      extendTheme({
        typography: {
          fontFamily: fontFamily ? fontFamily : 'Mulish, sans-serif',
          button: {
            fontWeight: 400,
            fontSize: '1rem',
            textTransform: 'none',
          },
          h1: {
            fontWeight: 800,
            fontSize: '1.875rem',
            textTransform: 'none',
          },
          h2: {
            fontWeight: 300,
            fontSize: '1.625rem',
            textTransform: 'none',
          },
          h3: {
            fontWeight: 300,
            fontSize: '1.375rem',
            lineHeight: '2',
            textTransform: 'none',
          },
          h4: {
            fontWeight: 800,
            fontSize: '1.25rem',
            textTransform: 'none',
          },
          h5: {
            fontWeight: 300,
            fontSize: '1.125rem',
            lineHeight: '2',
            textTransform: 'none',
          },
          h6: {
            fontWeight: 300,
            fontSize: '0.8125rem',
            textTransform: 'none',
          },
          subtitle1: {
            fontWeight: 400,
            fontSize: '1rem',
            textTransform: 'none',
          },
          subtitle2: {
            fontWeight: 400,
            fontSize: '0.875rem',
            textTransform: 'none',
          },
          body1: {
            fontWeight: 400,
            fontSize: '1rem',
            textTransform: 'none',
          },
          body2: {
            fontWeight: 400,
            fontSize: '0.875rem',
            textTransform: 'none',
          },
          caption: {
            fontWeight: 400,
            fontSize: '1rem',
            textTransform: 'none',
          },
          overline: {
            fontWeight: 400,
            fontSize: '1rem',
            textTransform: 'none',
          },
        },
        spacing: 4,
        components: {
          MuiButton: {
            styleOverrides: {
              contained: {
                padding: '8px 16px',
                backgroundColor: '#057EB4',
                color: '#fff',
                boxShadow: '0px 2px 12px #06ABF480',
                '& svg': {
                  fill: '#fff',
                },
                '&:hover': {
                  backgroundColor: '#2DB9F6',
                  boxShadow: '0px 2px 12px #06ABF480',
                },
                '&:disabled': {
                  color: '#999999',
                  '& svg': {
                    fill: '#999999',
                  },
                  shadow: 'none',
                },
              },
              text: {
                padding: '8px 16px',
                backgroundColor: '#fff',
                color: '#555555',
                '& svg': {
                  fill: '#555555',
                },
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#CFE9F5',
                  boxShadow: 'none',
                },
                '&:disabled': {
                  color: '#AAAAAA',
                  '& svg': {
                    fill: '#AAAAAA',
                  },
                  backgroundColor: '#fff',
                },
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides:
              customFont && fontFamily
                ? `
              @font-face {
                font-family: ${fontFamily};
                font-weight: 300;
                src: local(${fontFamily}), local('${fontFamily}-Light'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Light.woff2') format('woff2');
              }
              @font-face {
                font-family: ${fontFamily};
                font-weight: 400;
                src: local(${fontFamily}), local('${fontFamily}-Regular'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Regular.woff2') format('woff2');
              }
              @font-face {
                font-family: ${fontFamily};
                font-weight: 500;
                src: local(${fontFamily}), local('${fontFamily}-Medium'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Medium.woff2') format('woff2');
              }
              @font-face {
                font-family: ${fontFamily};
                font-weight: 700;
                src: local(${fontFamily}), local('${fontFamily}-Bold'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Bold.woff2') format('woff2');
              }
              @font-face {
                font-family: ${fontFamily};
                font-weight: 900;
                src: local(${fontFamily}), local('${fontFamily}-Black'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Black.woff2') format('woff2');
              }
            `
                : '',
          },
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: isMobileMode ? 100000 : SCREEN_SIZE.SMALL + 1, // add 1px because material ui produces a wrong value for breakpoint.up('xs'): max-width: 599.95px
            md: SCREEN_SIZE.MEDIUM + 1, // override standard 960px breakpoint and add 1px same as above
            lg: 1280,
            xl: 1920,
          },
        },
      }),
    ),
    colorSchemes: {
      ...commonTheme.colorSchemes,
      light: {
        ...commonTheme.colorSchemes?.light,
        palette: {
          ...commonTheme.colorSchemes?.light?.palette,
          background: {
            // ...commonTheme.colorSchemes?.light?.palette?.background,
            default: BigidMeColors.backgroundColor,
            // paper: 'transparent',
          },
          text: {
            primary: BigidMeColors.gray[775],
            secondary: BigidMeColors.gray[750],
            disabled: BigidMeColors.disabledText,
          },
          bigidMe: {
            ...BigidMeColorSchemesVars.light,
            ...(colors ? { ...colors, illustrationColor: makeIllustrationColor(colors.primary) } : {}),
          },
          bigid: {
            ...BigidColorSchemesVars.light,
          },
        },
      },
    },
    direction: isRtl ? 'rtl' : 'ltr',
  });
