import React, { useCallback, useMemo } from 'react';
import { Container, CssBaseline, Paper, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { BigidEditIcon } from '@bigid-ui/icons';

import { BigidMeWireframe } from '../../components';
import { TenantContainer } from '../../state/tenantContainer';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidBody1, BigidLoader, BigidToastNotification } from '@bigid-ui/components';
import { useFormValues, useFormValuesUpdate, useStepTitles } from './hooks/useDynamicForm';
import { DynamicFormContainer } from './DynamicFormContainer';
import { useStepper } from './hooks/useStepper';
import { useSetPageTitle } from './hooks';
import { MePageHeader } from '../../components/MePageHeader/MePageHeader';
import { FormSectionVariantType } from '../types/common';
import {
  ConfirmEmailStatus,
  PhoneVerificationDialog,
  RequestDetailsSectionForm,
  RequestSectionForm,
  SessionController,
} from './components';

import { ReactComponent as BigidMeIconStepActive } from '../../assets/icons/cur-step.svg';
import { ReactComponent as BigidMeIconStepCompleted } from '../../assets/icons/success.svg';
import { ReactComponent as BigidMeStepInactive } from '../../assets/icons/icon-step-disabled.svg';
import { ResponsiveStepperConnector } from './DynamicFormStyles';
import { AppTheme } from '../../theme/AppTheme';

const getStepIcon = (step: number, activeStep: number) =>
  step < activeStep ? (
    <BigidMeIconStepCompleted />
  ) : step == activeStep ? (
    <BigidMeIconStepActive />
  ) : (
    <BigidMeStepInactive />
  );

export const DynamicFormComponent = ({ site }: { site: number | string }) => {
  const { tenant } = TenantContainer.useContainer();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  useFormValues(site);
  useSetPageTitle();

  const { fetchData, submitData } = useFormValuesUpdate(site);
  const {
    state: { loading, normalizedSections, verificationContext, submittedRequest },
  } = DynamicFormContainer.useContainer();

  const { activeStep, handleNext, handleSetActiveStep } = useStepper();
  const { titles, subTitles } = useStepTitles();

  const handleSubmitRequest = useCallback(
    (lastFormValues: Record<string, string>) => {
      submitData({ lastFormValues });
    },
    [submitData],
  );

  const localeList = useMemo(() => tenant.locales?.map(({ code }) => code), [tenant.locales]);

  if (loading && !normalizedSections) {
    return <BigidLoader />;
  }

  const signInUrl = (verificationContext?.status === 'SIGNED_OUT' && verificationContext?.signInUrl) || '';

  return (
    <BigidMeWireframe {...tenant} localeList={localeList}>
      {loading && <BigidLoader />}
      {!submittedRequest && (
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            maxWidth: '970px',
            mt: 2,
            mb: 5,
            px: {
              xs: 1,
              lg: 0,
            },
          }}
        >
          <>
            <MePageHeader />
            <Stepper activeStep={activeStep} orientation="vertical" connector={<ResponsiveStepperConnector />}>
              <Step>
                <StepLabel
                  tabIndex={activeStep > 0 ? 0 : -1}
                  sx={{
                    cursor: activeStep > 0 ? 'pointer' : 'default',
                  }}
                  onClick={() => activeStep > 0 && handleSetActiveStep(0)}
                  onKeyUp={e => {
                    ['Enter', 'Space'].includes(e.code) && activeStep > 0 && handleSetActiveStep(0);
                  }}
                  icon={getStepIcon(0, activeStep)}
                >
                  <BigidBody1 fontSize="18px">
                    {titles?.[FormSectionVariantType.START_REQUEST]}
                    <i>{subTitles?.[FormSectionVariantType.START_REQUEST]}</i>
                    {activeStep > 0 && <BigidEditIcon style={{ verticalAlign: 'middle', margin: '0 4px' }} />}
                  </BigidBody1>
                </StepLabel>
                <StepContent>
                  <Paper
                    sx={{
                      p: '24px',
                      minHeight: '240px',
                      boxShadow: `0px 1.368px 17.102px 0px rgba(0, 0, 0, 0.10)`,
                    }}
                  >
                    <RequestSectionForm
                      onUpdate={values => fetchData(values, [FormSectionVariantType.START_REQUEST])}
                      onSubmit={handleNext}
                      section={FormSectionVariantType.START_REQUEST}
                      submitButtonText={BigIdMeTranslate('consumer_web_submit_request-preferences_next')}
                    />
                  </Paper>
                </StepContent>
              </Step>
              <Step>
                <StepLabel
                  sx={{
                    cursor: activeStep > 1 ? 'pointer' : 'default',
                  }}
                  onClick={() => activeStep > 1 && handleSetActiveStep(1)}
                  icon={getStepIcon(1, activeStep)}
                >
                  <BigidBody1 fontSize="18px">
                    {titles?.[FormSectionVariantType.REQUEST_TYPE]}
                    <i>{subTitles?.[FormSectionVariantType.REQUEST_TYPE]}</i>
                    {activeStep > 1 && <BigidEditIcon style={{ verticalAlign: 'middle', marginLeft: '4px' }} />}
                  </BigidBody1>
                </StepLabel>
                <StepContent>
                  <RequestSectionForm
                    margin="0"
                    onUpdate={async (values: Record<string, string>) => {
                      await fetchData(values, [
                        FormSectionVariantType.START_REQUEST,
                        FormSectionVariantType.REQUEST_TYPE,
                      ]);
                      handleNext();
                    }}
                    section={FormSectionVariantType.REQUEST_TYPE}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepLabel icon={getStepIcon(2, activeStep)}>
                  <BigidBody1 fontSize="18px">{titles?.[FormSectionVariantType.DETAILS]}</BigidBody1>
                </StepLabel>
                <StepContent>
                  <Paper sx={{ p: '24px', mb: '24px', boxShadow: `0px 1.368px 17.102px 0px rgba(0, 0, 0, 0.10)` }}>
                    {normalizedSections?.[FormSectionVariantType.AUTHORIZED_AGENT] ? (
                      // show 2 subsection as one
                      <RequestDetailsSectionForm
                        onUpdate={fetchData}
                        submitButtonText={BigIdMeTranslate('consumer_web_submit_request-preferences_submit')}
                        onSubmit={handleSubmitRequest}
                        captchaShown={tenant.captchaOn}
                        signInUrl={signInUrl}
                      />
                    ) : (
                      // show one section
                      <RequestSectionForm
                        showTitle
                        onUpdate={fetchData}
                        section={FormSectionVariantType.DATA_SUBJECT}
                        submitButtonText={BigIdMeTranslate('consumer_web_submit_request-preferences_submit')}
                        onSubmit={handleSubmitRequest}
                        captchaShown={tenant.captchaOn}
                        signInUrl={signInUrl}
                      />
                    )}
                  </Paper>
                </StepContent>
              </Step>
            </Stepper>
          </>
        </Container>
      )}
      {submittedRequest?.emailConfirmationRequired && (
        <ConfirmEmailStatus
          email={submittedRequest.emailConfirmationRecipient}
          requestId={submittedRequest.id}
          requestType={submittedRequest.requestType}
        />
      )}
      <SessionController />
    </BigidMeWireframe>
  );
};

export const DynamicForm = ({ site }: { site: number | string }) => {
  return (
    <AppTheme>
      <CssBaseline />
      <DynamicFormContainer.Provider>
        <DynamicFormComponent site={site} />
        <PhoneVerificationDialog />
      </DynamicFormContainer.Provider>
      <BigidToastNotification />
    </AppTheme>
  );
};
