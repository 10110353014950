import makeStyles from '@mui/styles/makeStyles';
import { errorColor } from '../../../../../../assets/theme';
import { BigidColorsV2 } from '@bigid-ui/components';

export const useStyles = makeStyles(theme => ({
  root: {},
  // Hidding flags icons in input and in a dropdown
  '@global': {
    '.react-tel-input': {
      width: 'auto',
      fontFamily: theme.typography.fontFamily,
      '& input': { fontFamily: theme.typography.fontFamily },
    },
    '[dir=rtl] .react-tel-input': {
      '& .selected-flag': {
        padding: '0 0 0 8px',
      },
      '& .arrow': {
        left: '20px',
      },
      '& .country-list .flag': {
        marginRight: '7px',
        marginLeft: 0,
      },
      '& .country-list .country-name': {
        display: 'inline-block',
        marginRight: '6px',
        marginLeft: 0,
      },
      '& .form-control': {
        direction: 'ltr!important',
      },
    },
    '.react-tel-input .flag-dropdown': {
      borderRadius: '4px 0 0 4px !important',
      border: `1px solid ${BigidColorsV2.gray[200]}`,
      zIndex: 1,
    },
    '.react-tel-input .selected-flag': {
      backgroundColor: 'transparent !important',

      '&:focus': {
        outline: 'auto',
      },
    },
    '.react-tel-input .form-control': {
      paddingLeft: '43px !important',
      borderRadius: '4px !important',
      border: `1px solid ${BigidColorsV2.gray[200]}`,
    },
    '.react-tel-input .flag-dropdown.open': {
      borderRadius: '4px 0 0 0 !important',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& .react-tel-input .form-control': {
      width: '100%',
      height: 40,
    },

    '& ul': {
      zIndex: 2,
    },
  },
  inputContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  verifyButton: {
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
  },
  inputRoot: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    '&::placeholder': {
      fontWeight: 400,
      color: '#949494',
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
    '&:focus-within': {
      outline: 'solid',
      outlineWidth: '1px',
      outlineColor: BigidColorsV2.purple[700],
    },
  },
  inputError: {
    '&.form-control': {
      border: `1px solid ${errorColor}`,
      '&:focus-within': {
        outline: 'none',
      },
    },
    '&.flag-dropdown': {
      border: `1px solid ${errorColor}`,
      borderRight: `1px solid ${BigidColorsV2.gray[200]}`,
      '&:focus-within': {
        outline: 'none',
      },
    },
  },
  inputDisabled: {
    backgroundColor: '#F8F8F8',
  },
  visualHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));
