import React, { FC, useState } from 'react';
import { BigidMeRequestStatusSubmitted } from '../../../../components';
import { LanguageContainer } from '../../../../state/languageContainer';
import { TenantContainer } from '../../../../state/tenantContainer';
import { resendConfirmationEmail } from '../../../services';
import { RedirectUrls } from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { ErrorCodes } from '../../../../constants/errorCodes';

type ConfirmEmailStatusPropsType = {
  email: string;
  requestId: string;
  requestType: string;
};

enum ConfirmationEmailResendResult {
  Successful,
  Limit,
}

export const ConfirmEmailStatus: FC<ConfirmEmailStatusPropsType> = ({ email, requestId, requestType }) => {
  const navigate = useNavigate();
  const [confirmationEmailResendResult, setConfirmationEmailResendResult] = useState<ConfirmationEmailResendResult>();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();
  const { tenant } = TenantContainer.useContainer();

  const handleResend = () => {
    resendConfirmationEmail(requestId)
      .then()
      .catch(err => {
        switch (err?.response?.data?.errorCode) {
          case ErrorCodes.EMAIL_RESEND_LIMIT:
            setConfirmationEmailResendResult(ConfirmationEmailResendResult.Limit);
            break;
          default:
            navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
        }
      });
  };

  return (
    <BigidMeRequestStatusSubmitted
      email={email}
      contactLinkUrl={tenant?.supportLink}
      requestedAction={requestType}
      resendExceeded={confirmationEmailResendResult === ConfirmationEmailResendResult.Limit}
      onResend={handleResend}
      useEmailConfirmation={true}
      translate={BigIdMeTranslate}
      translateWithNode={BigIdMeTranslateWithNode}
    />
  );
};
