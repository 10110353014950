import { styled } from '@mui/styles';
import StepConnector from '@mui/material/StepConnector';
import { stepConnectorClasses } from '@mui/material';

export const ResponsiveStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#01BFA5',
      borderLeftStyle: 'dashed',
      borderWidth: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#01BFA5',
      borderLeftStyle: 'dashed',
      borderWidth: 2,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
