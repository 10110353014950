import React, { FC } from 'react';
import { BigidBody1, BigidLink, PrimaryButton } from '@bigid-ui/components';
import { useForm } from 'react-hook-form';
import { Box, Divider, Stack } from '@mui/material';

import { FormFieldsSection } from '../FormFieldsSection';
import { DynamicFormContainer } from '../../DynamicFormContainer';
import { FormSectionVariantType, NormalizedFormFieldType, LayoutType } from '../../../types/common';
import { DynamicFormReducerActionType } from '../../state';
import { LanguageContainer } from '../../../../state/languageContainer';
import { normalizeFields } from '../../utils';
import { Captcha } from '../Captcha';

type RequestSectionFormComponentPropsType = {
  layout: LayoutType;
  fields: NormalizedFormFieldType[];
  submitButtonText?: string;
  onChange?: (values: Record<string, string>) => void;
  onSubmit: (values: Record<string, string>) => void;
  hideSubmit?: boolean;
  disableSubmit?: boolean;
  captchaShown?: boolean;
  margin?: string;
};

const RequestSectionFormComponent: FC<RequestSectionFormComponentPropsType> = ({
  layout,
  fields,
  onChange,
  onSubmit,
  submitButtonText = 'Next',
  hideSubmit,
  disableSubmit,
  captchaShown,
  margin,
}) => {
  const { control, handleSubmit, getValues } = useForm();

  const handleFieldsUpdate = () => {
    const values = getValues();
    onChange?.(values);
  };

  return (
    <>
      <FormFieldsSection
        compact={layout === 'compact'}
        control={control}
        fields={fields}
        onChange={handleFieldsUpdate}
        margin={margin}
      />
      {captchaShown && <Captcha />}
      {!hideSubmit && (
        <Box sx={{ mb: 1, mr: 3 }} textAlign="right">
          <PrimaryButton
            size="medium"
            onClick={handleSubmit(onSubmit)}
            disabled={disableSubmit}
            text={submitButtonText}
            dataAid={`${submitButtonText.toLocaleLowerCase()}-button`}
          />
        </Box>
      )}
    </>
  );
};

type RequestSectionFormPropsType = {
  showTitle?: boolean;
  captchaShown?: boolean;
  signInUrl?: string;
  onUpdate: (values: Record<string, string>) => void;
  section: FormSectionVariantType;
  submitButtonText?: string;
  onSubmit?: (values: Record<string, string>) => void;
  onBack?: () => void;
  margin?: string;
};

export const RequestSectionForm: FC<RequestSectionFormPropsType> = ({
  showTitle = false,
  captchaShown = false,
  signInUrl,
  onUpdate,
  section,
  submitButtonText = 'Next',
  onSubmit,
  margin,
}) => {
  const {
    dispatch,
    state: { forms, normalizedSections, layout },
  } = DynamicFormContainer.useContainer();

  const { language, BigIdMeTranslate } = LanguageContainer.useContainer();

  if (!forms || !normalizedSections?.[section]) {
    return null;
  }

  const title = normalizedSections[section].translations[language]?.title;

  const fields = normalizedSections[section].fields.map(field =>
    normalizeFields({
      field,
      locale: language,
      defaultValue: forms?.[field.name],
      translate: BigIdMeTranslate,
    }),
  );

  const disableSubmit = normalizedSections[section].fields.some(field => field.error?.code);

  const handleSubmit = (values: Record<string, string>) => {
    dispatch({
      type: DynamicFormReducerActionType.SET_FORM_VALUES,
      payload: {
        values,
      },
    });
    onSubmit?.(values);
  };

  return (
    <>
      {showTitle && title && (
        <Box p="24px 24px 0 24px">
          <Stack direction="row" justifyContent="space-between">
            <BigidBody1 fontWeight="bold">{title}</BigidBody1>
            {signInUrl && (
              <BigidBody1>
                <BigidLink
                  href={signInUrl}
                  underline="hover"
                  text={BigIdMeTranslate('consumer_web_submit_sign-in-link')}
                />
              </BigidBody1>
            )}
          </Stack>
          <Divider sx={{ mt: '12px' }} />
        </Box>
      )}
      <RequestSectionFormComponent
        margin={margin}
        layout={layout}
        fields={fields}
        onChange={onUpdate}
        onSubmit={handleSubmit}
        submitButtonText={submitButtonText}
        hideSubmit={!onSubmit}
        disableSubmit={disableSubmit}
        captchaShown={captchaShown}
      />
    </>
  );
};
