/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useState, ChangeEvent } from 'react';
import cx from 'classnames';
import { FileDrop } from 'react-file-drop';
import { Paper } from '@mui/material';

import { useStyles } from './BigidMeFileUploaderStyles';
import { ReactComponent as BigidMeIconCrossBlue } from '../../assets/icons/icon-cross-blue.svg';
import { ReactComponent as BigidMeIconImage } from '../../assets/icons/icon-image.svg';
import { ReactComponent as DeskUploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as MobUploadIcon } from '../../assets/icons/mob-upload.svg';
import { ReactComponent as ClearIcon } from '../../assets/icons/baseline-clear-24px.svg';
import { ReactComponent as MobPicUploaded } from '../../assets/icons/mob-pic-uploaded.svg';
import { ReactComponent as ProgressSuccess } from '../../assets/icons/progress-success.svg';
import { ReactComponent as IconMobId } from '../../assets/icons/mob-id.svg';
import { ReactComponent as IconMobLicense } from '../../assets/icons/mob-license.svg';
import { ReactComponent as IconMobPassport } from '../../assets/icons/mob-passport.svg';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeProgressBarInfinite } from '..';
import { ValidationPhotoType } from '../../services';
import { useResponsive } from '../../hooks/useResponsive';
import { SVGComponentType } from '../../utils';

export interface BigidMeFileUploaderPropsType {
  format: string;
  subtext?: string;
  onSelect: (file: FormData) => void;
  onRemove: () => void;
  onMobileValidationTypeChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  id?: string;
  hideMobileValidationTypes?: boolean;
}

// TODO: use BigidDropZone
export const BigidMeFileUploader: FC<BigidMeFileUploaderPropsType> = ({
  id,
  onSelect,
  onRemove,
  format,
  subtext = '',
  onMobileValidationTypeChange,
  label,
  hideMobileValidationTypes,
}) => {
  const isMobile = useResponsive();
  const mobileViewShown = !hideMobileValidationTypes && isMobile;
  const classes = useStyles();
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [loading, setLoading] = useState(false);
  const [isFileUploaded, setFileUploadStatus] = useState(false);
  const [showMobileIdTypeVelum, setMobileIdTypeVelum] = useState(false);
  const [uploadError, setUploadErrorStatus] = useState('');
  const [uploadedFileName, setUploadFileName] = useState('');

  const showTypeVelum = () => {
    setMobileIdTypeVelum(true);
  };

  const hideTypeVelum = () => {
    setMobileIdTypeVelum(false);
  };

  const onSelectFile = async (files: FileList | null) => {
    if (!files) return;

    if (files[0].size / 1000000 > 10 || (!files[0].type.includes('image') && files[0].type !== 'application/pdf')) {
      // File greater than 10MB
      removeLoadedFile();
      setUploadErrorStatus(BigIdMeTranslate('consumer_web_errors_wrong_format_size'));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', files[0]);
    setUploadFileName(files[0].name);
    await onSelect(formData);
    setLoading(false);
    setFileUploadStatus(true);
    setUploadErrorStatus('');
  };

  const removeLoadedFile = () => {
    setLoading(false);
    setFileUploadStatus(false);
    setUploadErrorStatus('');
    setUploadFileName('');
    onRemove();
  };

  const onFileChange = (event: { target: { files: FileList | null; value: string } }) => {
    onSelectFile(event.target.files);
    event.target.value = '';
  };

  const renderMobileValidationPhotoType = (value: ValidationPhotoType, label: string, Icon: SVGComponentType) => (
    <div
      className={classes.item}
      onClick={() =>
        onMobileValidationTypeChange &&
        onMobileValidationTypeChange({
          target: { value },
        } as unknown as ChangeEvent<HTMLInputElement>)
      }
    >
      <FileDrop onDrop={(files, _event) => onSelectFile(files)}>
        <Icon className={classes.iconId} title={BigIdMeTranslate(label)} />
        {BigIdMeTranslate(label)}
        <input type="file" accept={format} multiple={false} className={classes.upload} onChange={onFileChange} />
      </FileDrop>
    </div>
  );

  const renderUploadedView = () => {
    if (mobileViewShown) {
      return (
        <div className={classes.loadedArea}>
          <ClearIcon
            title={BigIdMeTranslate('consumer_web_clear')}
            className={classes.crossMobile}
            onClick={removeLoadedFile}
          />
          <MobPicUploaded />
          <br />
          <div className={classes.filename}>{uploadedFileName}</div>
          <ProgressSuccess className={classes.success} />
          <div className={classes.replace} onClick={showTypeVelum}>
            <span>{BigIdMeTranslate('consumer_web_mob_fileloader-replace')}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.loadedArea}>
          <BigidMeIconImage title={BigIdMeTranslate('consumer_web_image')} /> &nbsp;&nbsp;
          <div className={classes.filename}>{uploadedFileName}</div>
          <span className={classes.remove} onClick={removeLoadedFile} tabIndex={0}>
            &nbsp;
            <BigidMeIconCrossBlue title={BigIdMeTranslate('consumer_web_clear')} />
            &nbsp;{BigIdMeTranslate('consumer_web_submit_fileloader_remove')}
          </span>
        </div>
      );
    }
  };

  const UploadIcon = mobileViewShown ? MobUploadIcon : DeskUploadIcon;

  return (
    <div className={classes.root}>
      <div className={classes.dropdownArea}>
        {mobileViewShown ? (
          <FileDrop onDrop={(files, _event) => onSelectFile(files)}>
            {isFileUploaded && !loading && renderUploadedView()}
            <div className={classes.uploadMobileWrapper}>
              {!isFileUploaded && (
                <UploadIcon title={BigIdMeTranslate('consumer_web_mob_fileloader-upload-photo-your-id')} />
              )}
              {!isFileUploaded && !loading && (
                <>
                  <div className={classes.subtext}>{subtext}</div>
                  <div className={classes.uploadMobileInputWrapper} onClick={showTypeVelum}>
                    {BigIdMeTranslate('consumer_web_mob_fileloader-upload-photo-your-id')}
                    {uploadError && <div className={classes.error}>{uploadError}</div>}
                  </div>
                </>
              )}
            </div>
          </FileDrop>
        ) : (
          <FileDrop onDrop={(files, _event) => onSelectFile(files)}>
            {isFileUploaded && !loading && renderUploadedView()}
            <div className={classes.uploadWrapper}>
              {!isFileUploaded && <UploadIcon />}
              {!mobileViewShown && (
                <div className={classes.uploadInputWrapper}>
                  {BigIdMeTranslate('consumer_web_submit_fileloader_drop_your_file_here')}{' '}
                  <span className={classes.link}>{BigIdMeTranslate('consumer_web_submit_fileloader_browse')}</span>{' '}
                  {BigIdMeTranslate('consumer_web_submit_fileloader_your_computer')}
                  <input
                    id={id}
                    type="file"
                    accept={format}
                    multiple={false}
                    className={classes.upload}
                    onChange={onFileChange}
                    aria-label={label}
                  />
                  {uploadError && <div className={classes.error}>{uploadError}</div>}
                </div>
              )}
            </div>
            <div className={classes.subtext}>{subtext}</div>
          </FileDrop>
        )}
        {loading && (
          <div className={classes.loadingWrapper}>
            {!mobileViewShown && <UploadIcon />}
            <div>{BigIdMeTranslate('consumer_web_submit_uploading')}...</div>
            <div>
              <BigidMeProgressBarInfinite width={mobileViewShown ? '230px' : '293px'} />
              {mobileViewShown && <div className={classes.filename}>{uploadedFileName}</div>}
              <span className={classes.remove} onClick={removeLoadedFile}>
                &nbsp; &nbsp;
                {!mobileViewShown && <BigidMeIconCrossBlue />}
              </span>
            </div>
          </div>
        )}
      </div>
      {!hideMobileValidationTypes && (
        <div className={cx(classes.bgVelum, { [classes.isHidden]: !showMobileIdTypeVelum })} onClick={hideTypeVelum}>
          <div className={classes.idTypeVelum}>
            <Paper elevation={4} className={classes.paper}>
              {renderMobileValidationPhotoType(
                ValidationPhotoType.NATIONAL_STATE_ID,
                'consumer_web_mob_fileloader-upload-national-id',
                IconMobId,
              )}
              {renderMobileValidationPhotoType(
                ValidationPhotoType.DRIVING_LICENSE,
                'consumer_web_mob_fileloader-upload-driving-license',
                IconMobLicense,
              )}
              {renderMobileValidationPhotoType(
                ValidationPhotoType.PASSPORT,
                'consumer_web_mob_fileloader-upload-passport',
                IconMobPassport,
              )}
            </Paper>
            <ClearIcon
              title={BigIdMeTranslate('consumer_web_clear')}
              className={classes.crossMobile}
              onClick={hideTypeVelum}
            />
          </div>
        </div>
      )}
    </div>
  );
};
