import React, { FC, useEffect, useState } from 'react';
import { PasscodeType, requestService } from '../../services';
import { ErrorCodes } from '../../constants/errorCodes';
import { LanguageContainer } from '../../state/languageContainer';
import { BigidMeSmsVerification } from '../../components/BigidMeSmsVerification/BigidMeSmsVerification';
import { setPageTitle } from '../../utils/helpers';

export interface PhoneVerificationPropsType {
  phone: string;
  onPinVerificationSuccess: (validationId: string) => void;
}

export const PhoneVerification: FC<PhoneVerificationPropsType> = ({ phone, onPinVerificationSuccess }) => {
  const [code, setCode] = useState('');
  const [validationId, setValidationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<ErrorCodes>();
  const { language, BigIdMeTranslate } = LanguageContainer.useContainer();

  useEffect(() => {
    sendCode();
    setPageTitle(BigIdMeTranslate('page_title_phone_verification'));
  }, []);

  const sendCode = (): void => {
    setLoading(true);
    requestService
      .validatePhone(phone, language)
      .then(({ validationId }) => {
        setValidationId(validationId);
      })
      .catch(err => {
        const errorCode = err?.response?.data?.errorCode || ErrorCodes.UNEXPECTED;
        setErrorCode(errorCode);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePhoneVerification = () => {
    const request = requestService.validateSMSCode(code, validationId);

    request
      .then(({ validated }) => {
        validated && onPinVerificationSuccess(validationId);
        !validated && setErrorCode(ErrorCodes.SMS_VALIDATION_INVALID_CODE);
      })
      .catch(err => {
        setErrorCode(err.errorCode || err.response?.data?.errorCode || ErrorCodes.ACTION_UNAVAILABLE);
      });

    return request;
  };

  return (
    <BigidMeSmsVerification
      loading={loading}
      sendCode={sendCode}
      onCodeChange={setCode}
      errorCode={errorCode}
      submitTitle={BigIdMeTranslate('consumer_web_verify')}
      submitClick={handlePhoneVerification}
      passcodeType={PasscodeType.SMS}
      bi={{ eventType: 'phone_sms_verification' }}
    />
  );
};
