import React, { FC } from 'react';
import { Box } from '@mui/material';
import { BigidLockIcon } from '@bigid-ui/icons';
import { StyledParagraph } from './ParagraphStyles';
import { parseHTML } from '../../../../../../utils';
import { StyledRichText } from '../../../../../../components';

type ParagraphPropsType = {
  bgBlue?: boolean;
  text?: string;
  showIcon?: boolean;
};

export const Paragraph: FC<ParagraphPropsType> = ({ bgBlue, showIcon, text = '' }) =>
  text ? (
    <StyledParagraph bgBlue={bgBlue}>
      {showIcon && <BigidLockIcon />}
      <Box flexGrow={1}>
        <StyledRichText>{parseHTML(text)}</StyledRichText>
      </Box>
    </StyledParagraph>
  ) : (
    <div></div>
  );
