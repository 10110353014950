import React, { FC, useMemo } from 'react';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { BigidBody1, BigidColorsV2, BigidRadio } from '@bigid-ui/components';

import { PreferenceItemType } from '../../../types/common';
import { LanguageType } from '../../../../state/languageContainer';
import { parseHTML } from '../../../../utils';
import { StyledRichText } from '../../../../components';

type RadioFieldPropsType = {
  id: number;
  locale: LanguageType;
  items: PreferenceItemType[];
  onChange: (fieldId: number, itemId: number, refresh?: boolean) => void;
};

export const RadioField: FC<RadioFieldPropsType> = ({ id, locale, items, onChange }) => {
  const radioCheckedValue = useMemo(() => {
    return items.find(item => item.selected)?.id || 0;
  }, [items]);

  const shouldRefresh = useMemo(() => items.some(({ hasAffectingRule }) => hasAffectingRule), [items]);

  return (
    <RadioGroup name={'Select one'} value={radioCheckedValue}>
      {items.map(({ id: itemId, translations }) => (
        <FormControlLabel
          key={itemId}
          id={id?.toString()}
          value={itemId}
          labelPlacement="start"
          sx={{
            width: '100%',
            m: 0,
            py: '16px',
          }}
          control={
            <BigidRadio
              label=""
              onChange={() => onChange(id, +itemId, shouldRefresh)}
              aria-labelledby={`${id}-label`}
              sx={{
                margin: 0,
                flex: 1,
                justifyContent: 'flex-end',
              }}
            />
          }
          label={
            <>
              <BigidBody1 id={`${id}-label`}>{translations[locale]?.label}</BigidBody1>
              {translations[locale]?.description && (
                <BigidBody1 color={BigidColorsV2.gray[500]}>
                  <StyledRichText>{parseHTML(translations[locale].description!)}</StyledRichText>
                </BigidBody1>
              )}
            </>
          }
        />
      ))}
    </RadioGroup>
  );
};
