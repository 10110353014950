import React, { Dispatch, SetStateAction } from 'react';
import { BigidMeSessionController, BigidMeSessionEffect, SessionClosingStages } from '../../../../components';
import { useNavigate, useParams } from 'react-router-dom';
import { TenantContainer } from '../../../../state/tenantContainer';

export const SessionController = () => {
  const navigate = useNavigate();
  const { publishName } = useParams();
  const { tenant } = TenantContainer.useContainer();
  const {
    sessionClosingStage,
    setSessionClosingStage,
  }: {
    sessionClosingStage: SessionClosingStages;
    setSessionClosingStage: Dispatch<SetStateAction<SessionClosingStages>>;
  } = BigidMeSessionEffect({
    tenantId: tenant?.tenantId,
    navigate,
    startOver: true,
    publishName,
  });

  return (
    <BigidMeSessionController
      sessionClosingStage={sessionClosingStage}
      setSessionClosingStage={setSessionClosingStage}
    />
  );
};
