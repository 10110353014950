import { FormSectionVariantType, FormType } from '../../types/common';

export enum DynamicFormReducerActionType {
  SET_ACTIVE_STEP,
  SET_NEXT_STEP,
  SET_BACK_STEP,

  INIT_REQUEST_START,
  INIT_REQUEST_SUCCESS,
  INIT_REQUEST_FAILED,

  GET_REQUEST_FIELDS_START,
  GET_REQUEST_FIELDS_SUCCESS,
  GET_REQUEST_FIELDS_FAILED,

  SET_FORM_VALUES,

  SUBMIT_REQUEST_START,
  SUBMIT_REQUEST_SUCCESS,
  SUBMIT_REQUEST_FAILED,

  SET_VERIFY_PHONE_DIALOG_OPENED,

  SET_CAPTCHA,
}

export type SetActiveStepType = {
  type: DynamicFormReducerActionType.SET_ACTIVE_STEP;
  payload: number;
};

export type SetNextStepType = {
  type: DynamicFormReducerActionType.SET_NEXT_STEP;
  payload?: undefined;
};

export type SetBackStepType = {
  type: DynamicFormReducerActionType.SET_BACK_STEP;
  payload?: undefined;
};

export type InitRequestStartType = {
  type: DynamicFormReducerActionType.INIT_REQUEST_START;
  payload?: undefined;
};

export type InitRequestSuccessType = {
  type: DynamicFormReducerActionType.INIT_REQUEST_SUCCESS;
  payload: FormType;
};

export type InitRequestFailedType = {
  type: DynamicFormReducerActionType.INIT_REQUEST_FAILED;
  payload?: undefined;
};

export type GetRequestFieldsStartType = {
  type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_START;
  payload: {
    values: Record<string, string>;
  };
};

export type GetRequestFieldsSuccessType = {
  type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_SUCCESS;
  payload: {
    data: FormType;
    keepValuesSections?: FormSectionVariantType[];
  };
};

export type GetRequestFieldsFailedType = {
  type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_FAILED;
  payload?: undefined;
};

export type SubmitRequestStartType = {
  type: DynamicFormReducerActionType.SUBMIT_REQUEST_START;
  payload?: undefined;
};

export type SubmitRequestSuccessType = {
  type: DynamicFormReducerActionType.SUBMIT_REQUEST_SUCCESS;
  payload: {
    emailConfirmationRecipient: string;
    emailConfirmationRequired: boolean;
    id: string;
    rc: string;
    requestKey: string;
    requestType: string;
  };
};

export type SubmitRequestFailedType = {
  type: DynamicFormReducerActionType.SUBMIT_REQUEST_FAILED;
  payload?: undefined;
};

export type SetFormValuesType = {
  type: DynamicFormReducerActionType.SET_FORM_VALUES;
  payload: {
    values: Record<string, string>;
  };
};

export type SetCaptchaType = {
  type: DynamicFormReducerActionType.SET_CAPTCHA;
  payload: string;
};

export type SetVerifyDialogOpenedType = {
  type: DynamicFormReducerActionType.SET_VERIFY_PHONE_DIALOG_OPENED;
  payload: boolean;
};

export type DynamicFormReducerAction =
  | SetActiveStepType
  | SetNextStepType
  | SetBackStepType
  | InitRequestStartType
  | InitRequestSuccessType
  | InitRequestFailedType
  | GetRequestFieldsStartType
  | GetRequestFieldsSuccessType
  | GetRequestFieldsFailedType
  | SetFormValuesType
  | SubmitRequestStartType
  | SubmitRequestSuccessType
  | SubmitRequestFailedType
  | SetCaptchaType
  | SetVerifyDialogOpenedType;
