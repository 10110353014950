import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material';

const Asterisk = styled('span')({
  color: BigidColorsV2.red[700],
  marginLeft: '4px',
});

const Label = styled('label')(`
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`);

const FieldWrapper = styled('div')({
  // a11y
  'label.MuiFormLabel-root': {
    display: 'none',
  },
});

export const StyledFieldWrapper = {
  Asterisk,
  Label,
  FieldWrapper,
};
