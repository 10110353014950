import { LanguageType } from '../../state/languageContainer';

export enum FormSectionVariantType {
  START_REQUEST = 'START_REQUEST',
  REQUEST_TYPE = 'REQUEST_TYPE',
  DETAILS = 'DETAILS',
  DATA_SUBJECT = 'DATA_SUBJECT',
  AUTHORIZED_AGENT = 'AUTHORIZED_AGENT',
  PREFERENCE_CENTER = 'PREFERENCE_CENTER',
  CONSENTS = 'CONSENTS',
}

export enum FieldVariantType {
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  DELIVERY_METHOD = 'DELIVERY_METHOD',
  COUNTRY = 'COUNTRY',
  COUNTRY_DIVISION = 'COUNTRY_DIVISION',
  USER_TYPE = 'USER_TYPE',
  USER_PHOTO = 'USER_PHOTO',
  MULTI_LINE = 'MULTI_LINE',
  DATE = 'DATE',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  RADIO = 'RADIO',
  FILE = 'FILE',
  PARAGRAPH = 'PARAGRAPH',
  REQUESTOR_TYPE = 'REQUESTOR_TYPE',
  REQUEST_TYPE = 'REQUEST_TYPE',
}

export enum PreferenceVariantType {
  MULTI_SELECT = 'MULTI_SELECT',
  TOGGLE = 'TOGGLE',
  RADIO = 'RADIO',
  OPT_LIST = 'OPT_LIST',
}

export enum RequestVariantType {
  VIEW = 'View',
  UPDATE = 'Update',
  DELETE = 'Delete',
  PREFERENCES = 'Preferences',
  OPT_OUT = 'OptOut',
  APPEAL = 'Appeal',
}

export enum RequestorTypeType {
  SELF = 'SELF',
  AUTHORIZED_AGENT = 'AUTHORIZED_AGENT',
}

export enum ManagePreferencesStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

export enum VerificationProviderEnum {
  CUSTOM = 'MS',
  CATHO = 'Catho',
  EXPERIAN = 'Experian',
  LEXIS_NEXIS = 'LexisNexis',
}

export type LayoutType = 'default' | 'compact';

export type FormType = {
  brandId: number;
  brandName: string;
  id: number;
  siteName: string;
  layout: LayoutType;
  sections: FormSectionType[];
  verificationContext?: verificationContextType;
};

export type verificationContextType = {
  verificationId: number;
  verificationProvider: string;
  status: 'NOT_APPLICABLE' | 'SIGNED_OUT';
  signInUrl?: string;
  attributes?: {
    name: string;
    value: string;
  }[];
};

export type FormSectionType = {
  id: number;
  type: FormSectionVariantType;
  sections: FormSectionType[];
  fields: FormFieldType[];
  preferences: FormPreferenceType[];
  translations: FormSectionTranslationsType;
};

export type FormFieldType = {
  id?: number;
  affectingInRule: boolean;
  uuid: string;
  name: string;
  text?: string;
  error?: { code: number };
  type: FieldVariantType;
  enabled: boolean;
  hidden: boolean;
  masked: boolean;
  required: boolean;
  tooltipEnabled: boolean;
  dateFormat?: 'MM_DD_YYYY' | 'DD_MM_YYYY';
  paragraphIconEnabled: boolean;
  paragraphBlueBackgroundEnabled: boolean;
  translations: FieldTranslationsType;
  options: FormFieldOptionType[];
  validation?: string;
  readonly: boolean;
};

export type FormPreferenceType = {
  id: number;
  negativeEnabled: boolean;
  positiveEnabled: boolean;
  sectionDefault: 'CURRENT' | 'NONE';
  sectionOrder: number;
  selectAllEnabled: boolean;
  type: PreferenceVariantType;
  items: PreferenceItemType[];
  translations: Record<LanguageType, FormPreferenceTranslationsType>;
};

export type FormPreferenceTranslationsType = {
  title: string;
  subtitle?: string;
  hint?: string;
  negative?: string;
  positive?: string;
  selectAllLabel?: string;
};

export type PreferenceItemType = {
  enabled: boolean;
  hasAffectingRule: boolean;
  uuid: string;
  id: number;
  required: boolean;
  selected: boolean;
  translations: PreferenceItemTranslationsType;
};

export type PreferenceItemTranslationsType = Record<
  LanguageType,
  {
    label: string;
    description?: string;
  }
>;

export type FormFieldOptionType = {
  id: number;
  value: string;
  translations: FormFieldOptionTranslationsType;
  useAsDefault: boolean;
};

export type FormSectionTranslationsType = Record<
  LanguageType,
  {
    title: string;
    description?: string;
  }
>;
export type FormFieldOptionTranslationsType = Record<LanguageType, FieldOptionTranslatedPropertiesType>;
export type FieldTranslationsType = Record<LanguageType, FieldTranslatedPropertiesType>;

export type FieldTranslatedPropertiesType = {
  locale: LanguageType;
  label: string;
  defaultValue?: string;
  hint?: string;
  tooltip?: string;
  text?: string;
  description?: string;
};

export type FieldOptionTranslatedPropertiesType = {
  locale: LanguageType;
  label?: string;
  description?: string;
};

export type NormalizedFormFieldType = Omit<FormFieldType, 'uuid' | 'error' | 'enabled' | 'translations' | 'options'> &
  Omit<FieldTranslatedPropertiesType, 'locale' | 'hint'> & {
    placeholder?: string;
    errorMessage?: string;
    options: NormalizedFormFieldOption[];
  };

export type NormalizedFormFieldOption = {
  id: string;
  value: string;
  displayValue: string;
  description?: string;
};
