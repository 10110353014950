import React from 'react';
import { useInterval } from '@bigid-ui/components';
import { Box, Typography } from '@mui/material';

import { BigidMeModal } from '../../../components/BigidMeModal';
import { FieldVerificationsContainer } from './FieldVerificationsContainer';
import { BigidMeIconPinCode } from '../../../assets/icons/BigidMeIconPinCode';
import { LanguageContainer } from '../../../state/languageContainer';
import { StyledToken } from './FieldVerificationsStyles';

export const FieldVerificationsDialog = () => {
  const { openVerifyDialog, token, fieldVerifying, fieldVerificationList, checkFieldVerification } =
    FieldVerificationsContainer.useContainer();
  const { BigIdMeTranslate, BigIdMeTranslateWithNode } = LanguageContainer.useContainer();

  const pollingAllowed = openVerifyDialog && fieldVerificationList?.[fieldVerifying || ''].status !== 'FAILED';

  useInterval(checkFieldVerification, pollingAllowed ? 1000 : null);

  if (!fieldVerifying) {
    return null;
  }

  return (
    <BigidMeModal open={openVerifyDialog}>
      <Box textAlign="center">
        <Box my={2}>
          <BigidMeIconPinCode />
        </Box>
        <Typography variant="h4" color="textPrimary" mb={3}>
          {BigIdMeTranslateWithNode('consumer_web_verification', [
            {
              key: 'field_name',
              value: fieldVerificationList?.[fieldVerifying].label,
            },
          ])}
        </Typography>
        <Typography variant="h6" color="textPrimary" fontWeight={400} mb={3}>
          {BigIdMeTranslate('consumer_web_nafath_verification_subtitle')}
        </Typography>
        {token && <StyledToken>{token}</StyledToken>}
      </Box>
    </BigidMeModal>
  );
};
