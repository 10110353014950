import React, { FC, useState, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cx from 'classnames';
import { BigidBIEventData } from '@bigid-ui/utils';

import { MeSubmitButton } from '../MeSubmitButton';
import { LanguageContainer } from '../../state/languageContainer';
import { e2e } from '../../utils';

import { useStyles } from './BigidMeThumbnailStyles';
import { parseHTML } from '../../utils';

export interface BigidMeThumbnailPropsType {
  title: string;
  tooltip: string;
  icon: ReactNode;
  id?: string;
  onClick: () => Promise<unknown>;
  testId?: string;
  hoverPreview?: boolean;
  bi: BigidBIEventData;
  selected?: boolean;
  button?: ReactNode;
}

export const BigidMeThumbnail: FC<BigidMeThumbnailPropsType> = ({
  title,
  icon,
  tooltip,
  id,
  onClick,
  testId,
  hoverPreview,
  bi,
  selected = false,
  button,
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const classes = useStyles();
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div
      className={cx(classes.root, { 'hover-preview': hoverPreview }, selected && classes.selected)}
      {...e2e(`thumbnail_${id}`)}
      tabIndex={0}
      onKeyPress={() => setExpanded(!isExpanded)}
    >
      <div className={classes.facade} onClick={() => setExpanded(!isExpanded)}>
        <div className={classes.icon}>{icon}</div>
        <div data-aid={`${testId}-thumbnail-text`} className={classes.text}>
          {title}
        </div>
        <ExpandMoreIcon className={cx(classes.expandIcon, { [classes.expandIconActive]: isExpanded })} />
      </div>
      <div className={cx(classes.onhover, { [classes.expanded]: isExpanded })}>
        <div data-aid={`${testId}-thumbnail-tooltip`} className={classes.tooltip}>
          {parseHTML(tooltip)}
        </div>
        <span className={classes.selectButtonContainer} tabIndex={1}>
          {button ? (
            button
          ) : (
            <MeSubmitButton
              classes={classes.selectButton}
              type={'secondary'}
              onClick={onClick}
              data-aid={`thumbnail_${id}_select`}
              text={BigIdMeTranslate('consumer_web_submit_select')}
              bi={bi}
            />
          )}
        </span>
      </div>
    </div>
  );
};
