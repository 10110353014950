import React, { FC, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { BigidToastNotificationType, toastNotificationService } from '@bigid-ui/components';

import { BigidMeFileUploader } from '../../../../../../components/BigidMeFileUploader';
import { ErrorMessages } from '../../../../../../constants/errorCodes';
import { ErrorResponse } from '../../../../../../utils';
import { requestService } from '../../../../../../services';
import { LanguageContainer } from '../../../../../../state/languageContainer';

type FileFieldPropsType = {
  id: string;
  onChange: (value: string) => void;
};

export const FileField: FC<FileFieldPropsType> = ({ id, onChange }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const onSelectFile = useCallback(
    async (fileFormData: FormData) => {
      try {
        const uploadId = await requestService.uploadVerificationPhoto(fileFormData);
        onChange(uploadId);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const axiosError: AxiosError = err;
          toastNotificationService[BigidToastNotificationType.ERROR](
            BigIdMeTranslate(
              ErrorMessages[(axiosError?.response?.data as ErrorResponse).errorCode] || 'File upload fails',
            ),
          );
        }
      }
    },
    [onChange, BigIdMeTranslate],
  );

  const onRemove = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <BigidMeFileUploader
      hideMobileValidationTypes // a11y
      id={id}
      subtext={BigIdMeTranslate('consumer_web_submit_fileloader_png_jpeg_or_pdf_format_no_larger_than_10_mb')}
      format=".jpg, .jpeg, .png, .pdf"
      onSelect={onSelectFile}
      onRemove={onRemove}
      label="Click here to upload your file"
    />
  );
};
