import React, { FC, useState, useCallback, useEffect, InputHTMLAttributes } from 'react';
import { FormControl } from '@mui/material';
import PhoneInput from 'react-phone-input-with-accessibility';
import 'react-phone-input-with-accessibility/lib/style.css';
import cx from 'classnames';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { useStyles } from './PhoneFieldStyles';
import { NO_SMS_VERIFICATION, PhoneType } from '../../../../../../services';

import de from 'react-phone-input-with-accessibility/lang/de.json';
import { e2e } from '../../../../../../utils';
import { LanguageContainer } from '../../../../../../state/languageContainer';

export interface PhoneFieldProps {
  label?: string;
  id?: string;
  withSmsValidation?: boolean;
  tooltip?: string;
  placeholder?: string;
  error?: string;
  disabled?: boolean;
  onChange: (value: PhoneType) => void;
  onBlur?: () => void;
  defaultValue?: string; // can be country code ('ua') or full number (+38063...)
  dataAid?: string;
  isDisabled?: boolean;
}

export const PhoneField: FC<PhoneFieldProps> = ({
  id,
  placeholder,
  error,
  onChange,
  onBlur,
  defaultValue,
  withSmsValidation,
  dataAid,
  isDisabled,
}) => {
  const { BigIdMeTranslate, language } = LanguageContainer.useContainer();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [defaultCountryCode, setDefaultCountryCode] = useState<string | undefined>(undefined);
  const classes = useStyles({ hasValue: !!phoneNumber });
  const styleClasses = cx(classes.inputRoot, {
    [classes.inputError]: error,
    [classes.inputDisabled]: isDisabled,
  });

  const handleOnChange = useCallback(
    (phoneNumber: string, data: { countryCode: string }): void => {
      setPhoneNumber(phoneNumber);

      onChange({
        validationId: withSmsValidation ? undefined : NO_SMS_VERIFICATION,
        countryCode: data.countryCode,
        phone: phoneNumber ? '+' + phoneNumber : '',
      });
    },
    [onChange, withSmsValidation],
  );

  useEffect(() => {
    function initializeDefaultCountryCode() {
      if (!defaultValue) {
        return;
      }
      if (defaultValue?.length == 2) {
        setDefaultCountryCode(defaultValue);
      } else {
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
          const phone = defaultValue && phoneUtil.parse(defaultValue);
          phone && setPhoneNumber(phone.getCountryCode() + '' + phone.getNationalNumber());
        } catch (err) {
          console.error(err);
        }
      }
    }
    initializeDefaultCountryCode();
  }, [defaultValue]);

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  return (
    <FormControl fullWidth>
      {/* Hidden label for country code (accessibility) */}
      <label className={classes.visualHidden} id={`countryCodeLabel-${id}`}>
        {BigIdMeTranslate('consumer_web_submit_country_code_label')}
      </label>
      <div className={classes.container}>
        <div className={classes.inputContainer}>
          <PhoneInput
            id={id}
            country={defaultCountryCode}
            value={phoneNumber}
            placeholder={placeholder}
            prefix="+"
            disabled={isDisabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputClass={styleClasses}
            buttonClass={styleClasses}
            localization={language === 'de' ? de : undefined}
            inputProps={
              {
                ...e2e(dataAid),
                ['aria-describedby']: error ? `${id}-error` : undefined,
                ['aria-labelledby']: id,
                tabIndex: 0,
                id,
              } as InputHTMLAttributes<HTMLInputElement>
            }
          />
        </div>
      </div>
    </FormControl>
  );
};
