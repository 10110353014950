import React from 'react';

import { useManagePreferences } from './hooks';
import { BigidMeWireframe } from '../../components';
import { TenantContainer } from '../../state/tenantContainer';
import { Preferences } from './components';
import { BigidLoader } from '@bigid-ui/components';
import { PreferencesRequestStatus } from '../../screens/ManagePreferences/PreferencesRequestStatus'; // move to components
import { ManagePreferencesStatus } from '../types/common';
import { Container } from '@mui/material';
import { AppTheme } from '../../theme/AppTheme';

export const ManagePreferencesV2 = () => {
  const { tenant } = TenantContainer.useContainer();
  const {
    loading,
    locale,
    status,
    preferences,
    handleItemChange,
    handleAllItemChange,
    handleRadioItemChange,
    handleSubmit,
    handleSubmitAgain,
  } = useManagePreferences();

  return (
    <BigidMeWireframe hideLanguageControls {...tenant}>
      <AppTheme>
        <Container
          maxWidth={false}
          sx={{
            maxWidth: '970px',
            my: 5,
            px: {
              xs: 1,
              lg: 0,
            },
          }}
        >
          {preferences && status === ManagePreferencesStatus.COMPLETED && (
            <Preferences
              locale={locale}
              items={preferences}
              onItemChange={handleItemChange}
              onAllItemChange={handleAllItemChange}
              onRadioItemChange={handleRadioItemChange}
              onSubmit={handleSubmit}
            />
          )}
          {status && status !== ManagePreferencesStatus.COMPLETED && (
            <PreferencesRequestStatus status={status} onSubmit={handleSubmitAgain} />
          )}
          {loading && <BigidLoader />}
        </Container>
      </AppTheme>
    </BigidMeWireframe>
  );
};
