import { SupportedColorScheme } from '@mui/material';
import { BigidSchemeCustomVars } from '@bigid-ui/components';
import { BigidMeColors } from './BigidMeColors';
import { makeIllustrationColor } from '../../utils';

declare module '@mui/material/styles' {
  interface ColorSchemeOverrides {
    cloudLight: true;
  }

  interface PaletteOptions {
    bigidMe: BigidMeSchemeCustomVars;
    bigid?: BigidSchemeCustomVars;
  }

  interface Palette {
    bigidMe: BigidMeSchemeCustomVars;
    bigid?: BigidSchemeCustomVars;
  }
}

export interface BigidMeSchemeCustomVars {
  primary: string;
  illustrationColor: string;
  backgroundElement: string;
  font?: TenantFontType;
}

export type TenantFontType = {
  custom: boolean;
  family: string;
};

export enum ColorSchemes {
  LIGHT = 'light',
}

const lightColorScheme: BigidMeSchemeCustomVars = {
  primary: BigidMeColors.blue[750],
  illustrationColor: makeIllustrationColor(BigidMeColors.blue[750]),
  backgroundElement: BigidMeColors.blue[75],
};

export const BigidMeColorSchemesVars: Record<SupportedColorScheme, BigidMeSchemeCustomVars> = {
  light: lightColorScheme,
  dark: lightColorScheme,
  cloudLight: lightColorScheme,
};
