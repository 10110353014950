import { ErrorCodes } from '../constants/errorCodes';
import { SettingsTexts } from '../services';
import { LanguageType } from '../state/languageContainer';
import { BigidMeSchemeCustomVars } from '../assets/theme';
import { AvailableLanguagesType } from '../services/language/types';

export type ErrorResponse = {
  errorCode: ErrorCodes;
};

export type SVGComponentType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export type TranslationsType = Partial<
  Record<
    LanguageType,
    {
      [SettingsTexts.COPYRIGHT]?: string;
      [SettingsTexts.PAGE_TITLE]?: string;
      [SettingsTexts.PAGE_SUBTITLE]?: string;
      [SettingsTexts.HEADER_TITLE]?: string;
      [SettingsTexts.POSITIVE]?: string;
      [SettingsTexts.NEGATIVE]?: string;
      [SettingsTexts.DESCRIPTION]?: string;
      [SettingsTexts.SUPPORT_TITLE]?: string;
      [SettingsTexts.PRIVACY_POLICY_TITLE]?: string;
      [SettingsTexts.TERMS_CONDITIONS_TITLE]?: string;
    }
  >
>;
export interface Tenant {
  // V1
  tenantId: string;
  tenantName: string;
  logoUrl: string;
  favicon?: string;
  logoWidth: string | number;
  logoHeight: string | number;
  supportLink: string;
  termsConditionsLink: string;
  privacyPolicyLink: string;
  whitelabelBigidmeIcon?: boolean;
  captchaOn?: boolean;
  smsValidationOn?: boolean;
  correctionFlowEnabled?: boolean;
  translations: TranslationsType;
  // Privacy Portal force props for previewDataPP
  isPreviewPP?: boolean;
  weStore?: string;
  copyrightEnabled?: boolean;
  theme: BigidMeSchemeCustomVars;
  analyticsConfig: {
    enabled: boolean;
    serverUrl: string;
    apiKey: string;
    environment: string;
    tenantId: string;
    companyName: string;
  };
  monitoringConfig: {
    enabled: boolean;
    applicationIdPrivacyPortalApp: string;
    applicationIdConsumerApp: string;
    clientTokenPrivacyPortalApp: string;
    clientTokenConsumerApp: string;
    ddVersion: string;
  };

  locales?: AvailableLanguagesType;

  // V2
  dynamicFormEnabled?: boolean;
}
