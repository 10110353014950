import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/styles';

export const StyledToken = styled('div')({
  margin: '18px auto',
  width: 60,
  height: 60,
  lineHeight: '60px',
  borderRadius: '50%',
  fontSize: 22,
  textAlign: 'center',
  background: BigidColorsV2.gray[150],
  fontWeight: 'bold',
});
