import React, { FC } from 'react';
import { Box, Divider, Paper } from '@mui/material';
import { BigidBody1, BigidColorsV2, PrimaryButton } from '@bigid-ui/components';

import { FormPreferenceType, PreferenceVariantType } from '../../../types/common';
import { MultiSelectField } from '../MultiSelectField';
import { ToggleField } from '../ToggleField';
import { RadioField } from '../RadioField';
import { OptOutField } from '../OptOutField';
import { LanguageContainer, LanguageType } from '../../../../state/languageContainer';
import { BigidMeTooltip } from '../../../../components';
import { e2e, parseHTML } from '../../../../utils';
import { MePageHeader } from '../../../../components/MePageHeader/MePageHeader';

type PreferencesPropsType = {
  locale: LanguageType;
  items: FormPreferenceType[];
  onItemChange: (fieldId: number, itemId: number, itemSelected: boolean, refresh?: boolean) => void;
  onAllItemChange: (fieldId: number, allSelected: boolean) => void;
  onRadioItemChange: (fieldId: number, itemId: number, refresh?: boolean) => void;
  onSubmit: () => void;
};

export const Preferences: FC<PreferencesPropsType> = ({
  locale,
  items,
  onItemChange,
  onAllItemChange,
  onRadioItemChange,
  onSubmit,
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  return (
    <>
      <MePageHeader />
      {items.map(({ id, items, type, selectAllEnabled, positiveEnabled, negativeEnabled, translations }) => (
        <Paper
          key={id}
          sx={{ p: 3, mb: 5, bgcolor: '#fff', boxShadow: `0px 1.368px 17.102px 0px rgba(0, 0, 0, 0.10)` }}
        >
          <BigidBody1 fontWeight={700} {...e2e(`section-${id}_title`)}>
            {translations[locale]?.title}
            {translations[locale]?.hint && (
              <BigidMeTooltip title={translations[locale].hint || ''} {...e2e(`section-${id}_hint`)} />
            )}
          </BigidBody1>
          {translations[locale]?.subtitle && (
            <BigidBody1 color={BigidColorsV2.gray[500]} size="small" fontWeight={400} {...e2e(`${type}_subtitle`)}>
              {parseHTML(translations[locale].subtitle || '')}
            </BigidBody1>
          )}
          <Divider sx={{ mt: 2.5 }} />
          {type === PreferenceVariantType.MULTI_SELECT ? (
            <MultiSelectField
              id={id}
              selectAllEnabled={selectAllEnabled}
              locale={locale}
              items={items}
              onAllClick={onAllItemChange}
              onChange={onItemChange}
              selectAllLabel={translations[locale]?.selectAllLabel}
            />
          ) : type === PreferenceVariantType.TOGGLE ? (
            <ToggleField id={id} locale={locale} items={items} onChange={onItemChange} />
          ) : type === PreferenceVariantType.RADIO ? (
            <RadioField id={id} locale={locale} items={items} onChange={onRadioItemChange} />
          ) : type === PreferenceVariantType.OPT_LIST ? (
            <OptOutField
              id={id}
              locale={locale}
              items={items}
              onChange={onItemChange}
              positiveEnabled={positiveEnabled}
              negativeEnabled={negativeEnabled}
              positiveLabel={translations[locale]?.positive}
              negativeLabel={translations[locale]?.negative}
            />
          ) : (
            <p>{id}</p>
          )}
        </Paper>
      ))}
      <Box textAlign="right">
        <PrimaryButton
          size="medium"
          text={BigIdMeTranslate('consumer_web_submit_request-preferences_submit')}
          onClick={onSubmit}
        />
      </Box>
    </>
  );
};
