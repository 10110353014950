import React, { FC, ReactNode } from 'react';
import { FormControl, Box, Stack } from '@mui/material';

import { LanguageContainer } from '../../state/languageContainer';
import { HelperContainer, RequiredText, StyledFormHelperText, StyledInputLabel } from './FieldWrapperStyles';
import { e2e, WithTest } from '../../utils';
import { FieldHelpText } from './FieldHelpText';

export interface FieldWrapperPropsType extends WithTest {
  label?: ReactNode;
  required?: boolean;
  error?: string;
  customClasses?: {
    root?: string;
    labelRoot?: string;
    inputRoot?: string;
  };
  id?: string;
  helper?: string;
  fullWidth?: boolean;
  children: ReactNode;
  helperEndAdornment?: ReactNode;
  hiddenLabel?: boolean;
}

export const FieldWrapper: FC<FieldWrapperPropsType> = ({
  label,
  required,
  error,
  customClasses = {},
  id,
  helper,
  children,
  fullWidth,
  helperEndAdornment,
  hiddenLabel,
  ...rest
}) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  return (
    <FormControl fullWidth classes={{ root: customClasses?.root }}>
      <Stack>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ sm: 'start', md: 'center' }}>
          <Box position="relative" width={fullWidth ? '100%' : undefined}>
            {label && (
              <StyledInputLabel
                shrink
                classes={{ root: customClasses?.labelRoot }}
                htmlFor={id}
                hidden={hiddenLabel}
                {...e2e(`label_${rest['data-aid']}`)}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {label}
                </Box>
                {required && (
                  <RequiredText>&nbsp;({BigIdMeTranslate('consumer_web_validation_required')})</RequiredText>
                )}
              </StyledInputLabel>
            )}
            {children}
          </Box>
          {helper && (
            <HelperContainer fullWidth={fullWidth}>
              <FieldHelpText text={helper} endAdornment={helperEndAdornment} {...e2e(`${rest['data-aid']}_tooltip`)} />
            </HelperContainer>
          )}
        </Stack>
        {error && (
          <StyledFormHelperText error {...e2e(rest['data-aid'], '-error')} id={`${id}-error`} fullWidth={fullWidth}>
            {error}
          </StyledFormHelperText>
        )}
      </Stack>
    </FormControl>
  );
};
