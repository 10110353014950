import { useCallback, useEffect, useRef, useState } from 'react';
import { checkEmail, postPreferenceForms, updatePreferences } from '../../services';
import { useParams } from 'react-router-dom';
import { FormPreferenceType, ManagePreferencesStatus } from '../../types/common';
import { findIndex } from 'lodash';
import { LanguageType } from '../../../state/languageContainer';

const POLLING_INTERVAL = 5000;

export const useManagePreferences = () => {
  const [preferences, setPreferences] = useState<FormPreferenceType[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [locale, setLocale] = useState<LanguageType>(LanguageType.EN);
  const [preferencesStatus, setPreferencesStatus] = useState<ManagePreferencesStatus>();
  const pollingTimeout = useRef<number>();
  const { requestId, rc } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, status, userLocale } = await postPreferenceForms(requestId!, rc!);
        setLocale(userLocale);
        data?.sections && setPreferences(data.sections[0].preferences);
        setPreferencesStatus(status);

        if (status === ManagePreferencesStatus.PENDING) {
          pollingTimeout.current = window.setTimeout(() => fetchData(), POLLING_INTERVAL);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        setPreferencesStatus(ManagePreferencesStatus.ERROR);
        pollingTimeout.current = window.setTimeout(() => fetchData(), POLLING_INTERVAL);
      }
    };
    fetchData();

    return () => {
      pollingTimeout.current && window.clearTimeout(pollingTimeout.current);
    };
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updatePreferences(requestId!, rc!, preferences);
      setPreferencesStatus(ManagePreferencesStatus.SUBMITTED);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setPreferencesStatus(ManagePreferencesStatus.ERROR);
    }
  };

  const handleCheckEmail = async () => {
    try {
      const { data, status, userLocale } = await checkEmail(requestId!, rc!);
      setPreferences(data.sections[0].preferences);
      setLocale(userLocale);
      setPreferencesStatus(status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmitAgain = async () => {
    if (preferencesStatus === ManagePreferencesStatus.ERROR && preferences) {
      handleSubmit();
      // refetch ??
    }

    if (preferencesStatus === ManagePreferencesStatus.NOT_FOUND) {
      handleCheckEmail();
    }
  };

  const updatedData = useCallback(async (newPreferences: FormPreferenceType[], fieldId: number) => {
    setLoading(true);
    try {
      const allItems = newPreferences
        .map(({ items }) => items)
        .flat()
        .filter(({ selected }) => selected !== undefined)
        .map(({ uuid, selected }) => ({
          uuid,
          value: String(selected),
        }));

      const { data, status, userLocale } = await postPreferenceForms(requestId!, rc!, allItems);

      const fieldIndex = findIndex(newPreferences, item => item.id === fieldId);

      if (fieldIndex === -1) {
        setPreferences(data.sections[0].preferences);
        setLocale(userLocale);
        setPreferencesStatus(status);
        setLoading(false);
        return;
      }

      setPreferences(
        data.sections[0].preferences.map((item, index) =>
          index < fieldIndex
            ? newPreferences[index]
            : index === fieldIndex
            ? {
                ...item,
                items: item.items.map(
                  fieldItem =>
                    newPreferences[index].items.find(newFieldItem => newFieldItem.uuid === fieldItem.uuid) || fieldItem,
                ),
              }
            : item,
        ),
      );
      setLocale(userLocale);
      setPreferencesStatus(status);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const handleItemChange = (fieldId: number, itemId: number, itemSelected: boolean, refresh?: boolean) => {
    const getUpdatedItemInPreferences = (data: FormPreferenceType[]) =>
      data.map(field =>
        field.id === fieldId
          ? {
              ...field,
              items: field.items.map(item =>
                item.id === itemId
                  ? {
                      ...item,
                      selected: itemSelected,
                    }
                  : item,
              ),
            }
          : field,
      );

    if (refresh) {
      updatedData(getUpdatedItemInPreferences(preferences || []), fieldId);
      return;
    }

    setPreferences(prevData => getUpdatedItemInPreferences(prevData || []));
  };

  const handleAllItemChange = (fieldId: number, allSelected: boolean, refresh?: boolean) => {
    const getUpdatedItemInPreferences = (data: FormPreferenceType[]) =>
      data?.map(field =>
        field.id === fieldId
          ? {
              ...field,
              items: field.items.map(item => ({
                ...item,
                selected: allSelected,
              })),
            }
          : field,
      );

    if (refresh) {
      updatedData(getUpdatedItemInPreferences(preferences || []), fieldId);
      return;
    }

    setPreferences(prevData => getUpdatedItemInPreferences(prevData || []));
  };

  const handleRadioItemChange = (fieldId: number, itemId: number, refresh?: boolean) => {
    const getUpdatedItemInPreferences = (data: FormPreferenceType[]) =>
      data?.map(field =>
        field.id === fieldId
          ? {
              ...field,
              items: field.items.map(item =>
                item.id === itemId
                  ? {
                      ...item,
                      selected: true,
                    }
                  : {
                      ...item,
                      selected: false,
                    },
              ),
            }
          : field,
      );

    if (refresh) {
      updatedData(getUpdatedItemInPreferences(preferences || []), fieldId);
      return;
    }

    setPreferences(prevData => getUpdatedItemInPreferences(prevData || []));
  };

  return {
    loading,
    locale,
    status: preferencesStatus,
    preferences,
    handleItemChange,
    handleAllItemChange,
    handleRadioItemChange,
    handleSubmit,
    handleSubmitAgain,
  };
};
