import React, { FC } from 'react';
import { TemplateField } from '../TemplateField';

import { FieldVariantType, NormalizedFormFieldType } from '../../../types/common';
import { Paragraph } from './components';
import Grid2 from '@mui/material/Unstable_Grid2';

export const fullWidthFieldList = [
  FieldVariantType.REQUESTOR_TYPE,
  FieldVariantType.REQUEST_TYPE,
  FieldVariantType.PARAGRAPH,
  FieldVariantType.FILE,
  FieldVariantType.USER_PHOTO,
];

type FormFieldsSectionPropsType = {
  compact?: boolean;
  fields: NormalizedFormFieldType[];
  control: any;
  onChange: (fieldName: string) => void;
  margin?: string;
};

export const FormFieldsSection: FC<FormFieldsSectionPropsType> = ({ compact, fields, control, margin, onChange }) => {
  const handleChange = (fieldName: string) => () => {
    onChange(fieldName);
  };

  return (
    <Grid2
      container
      spacing={6}
      sx={{
        justifyContent: 'left',
        margin: margin || '0 auto 20px auto',
      }}
      columnSpacing={{
        xs: 0,
        md: 6,
      }}
    >
      {fields.map(field => (
        <React.Fragment key={field.name}>
          <Grid2
            display={field.hidden ? 'none' : 'block'}
            xs={12}
            md={compact ? 12 : fullWidthFieldList.includes(field.type) ? 12 : 6}
            sx={{
              p: field.type === FieldVariantType.PARAGRAPH && !field.text ? 0 : 3,
              ...(compact
                ? {
                    pr: {
                      md: [
                        FieldVariantType.PARAGRAPH,
                        FieldVariantType.REQUESTOR_TYPE,
                        FieldVariantType.REQUEST_TYPE,
                      ].includes(field.type)
                        ? 0
                        : '50%',
                    },
                  }
                : {}),
            }}
          >
            {field.type === FieldVariantType.PARAGRAPH ? (
              <Paragraph
                showIcon={field.paragraphIconEnabled}
                bgBlue={field.paragraphBlueBackgroundEnabled}
                text={field.text}
              />
            ) : (
              <TemplateField
                formField={field}
                control={control}
                onChange={
                  field.affectingInRule || field.type === FieldVariantType.REQUESTOR_TYPE
                    ? handleChange(field.name)
                    : undefined
                }
              />
            )}
          </Grid2>
        </React.Fragment>
      ))}
    </Grid2>
  );
};
