import { FormSectionType, FormSectionVariantType, LayoutType, verificationContextType } from '../../types/common';
import { DynamicFormReducerAction, DynamicFormReducerActionType } from './actions';
import {
  getFormsDefaultFieldsValues,
  getFormsFieldMap,
  normalizeSections,
  updateFormsDefaultFieldsValues,
} from './utils';

export type DynamicFormReducerStateType = {
  loading: boolean;
  activeStep: number;
  layout: LayoutType;
  sections: FormSectionType[];
  normalizedSections: Record<FormSectionVariantType, FormSectionType> | null;
  forms: Record<string, string> | null;
  fieldMap: Record<string, string> | null;
  captcha: string;
  verifyPhoneDialogOpened: boolean;
  submittedRequest: {
    emailConfirmationRecipient: string;
    emailConfirmationRequired: boolean;
    id: string;
    rc: string;
    requestKey: string;
    requestType: string;
  } | null;
  verificationContext?: verificationContextType;
};

export const initialDynamicFormReducerState: DynamicFormReducerStateType = {
  loading: false,
  activeStep: 0,
  layout: 'default',
  sections: [],
  normalizedSections: null,
  forms: null,
  fieldMap: null,
  captcha: '',
  verifyPhoneDialogOpened: false,
  submittedRequest: null,
};

export const dynamicFormReducer = (state: DynamicFormReducerStateType, action: DynamicFormReducerAction) => {
  const { type, payload } = action;
  switch (type) {
    case DynamicFormReducerActionType.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: payload,
      };
    }
    case DynamicFormReducerActionType.SET_NEXT_STEP: {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }

    case DynamicFormReducerActionType.SET_BACK_STEP: {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }
    case DynamicFormReducerActionType.SET_CAPTCHA: {
      return {
        ...state,
        captcha: payload,
      };
    }
    case DynamicFormReducerActionType.SET_VERIFY_PHONE_DIALOG_OPENED: {
      return {
        ...state,
        verifyPhoneDialogOpened: payload,
      };
    }
    case DynamicFormReducerActionType.INIT_REQUEST_START: {
      return {
        ...state,
        ...initialDynamicFormReducerState,
        loading: true,
        verifyPhoneDialogOpened: false,
      };
    }
    case DynamicFormReducerActionType.INIT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
        sections: payload.sections,
        normalizedSections: normalizeSections(payload.sections),
        layout: payload.layout,
        verificationContext: payload.verificationContext,

        forms: getFormsDefaultFieldsValues(payload.sections),
        fieldMap: getFormsFieldMap(payload.sections),
      };
    }
    case DynamicFormReducerActionType.SET_FORM_VALUES: {
      return {
        ...state,
        forms: state.forms
          ? {
              ...state.forms,
              ...payload.values,
            }
          : null,
      };
    }
    case DynamicFormReducerActionType.GET_REQUEST_FIELDS_START: {
      return {
        ...state,
        loading: true,
        forms: state.forms
          ? {
              ...state.forms,
              ...payload.values,
            }
          : null,
      };
    }
    case DynamicFormReducerActionType.GET_REQUEST_FIELDS_SUCCESS: {
      const { data, keepValuesSections } = payload;
      return {
        ...state,
        loading: false,
        sections: data.sections,
        normalizedSections: normalizeSections(data.sections),
        layout: data.layout,
        verificationContext: data.verificationContext,

        forms: state.forms
          ? updateFormsDefaultFieldsValues(state.forms, data.sections, keepValuesSections) // keep fields from active section
          : getFormsDefaultFieldsValues(data.sections),
        fieldMap: getFormsFieldMap(data.sections),
      };
    }
    case DynamicFormReducerActionType.GET_REQUEST_FIELDS_FAILED:
    case DynamicFormReducerActionType.INIT_REQUEST_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case DynamicFormReducerActionType.SUBMIT_REQUEST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case DynamicFormReducerActionType.SUBMIT_REQUEST_SUCCESS: {
      return {
        ...state,
        submittedRequest: payload,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
