import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material';

export const StyledParagraph = styled('div')<{ bgBlue?: boolean }>(({ bgBlue }) => ({
  padding: '8px',
  background: bgBlue ? BigidColorsV2.blue[100] : 'unset',
  borderRadius: '4px',
  fontSize: '12px',

  display: 'flex',
  gap: '8px',
  alignItems: 'center',

  '& > svg': {
    flexShrink: 0,
  },
}));
