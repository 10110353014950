import React, { FC } from 'react';
import { Box, FormGroup } from '@mui/material';
import { PreferenceItemType } from '../../../types/common';
import { LanguageType } from '../../../../state/languageContainer';
import { BigidCheckbox } from '@bigid-ui/components';

type MultiSelectFieldPropsType = {
  id: number;
  selectAllEnabled?: boolean;
  selectAllLabel?: string;
  locale: LanguageType;
  items: PreferenceItemType[];
  onChange: (fieldId: number, itemId: number, checked: boolean, refresh?: boolean) => void;
  onAllClick: (fieldId: number, checked: boolean, refresh?: boolean) => void;
};

export const MultiSelectField: FC<MultiSelectFieldPropsType> = ({
  id,
  locale,
  selectAllEnabled,
  selectAllLabel = 'All',
  items,
  onAllClick,
  onChange,
}) => (
  <FormGroup>
    {selectAllEnabled && (
      <Box py="12px">
        <BigidCheckbox
          name="all"
          label={selectAllLabel || ''}
          checked={items.filter(({ selected }) => selected).length === items.length}
          onChange={(_, checked) =>
            onAllClick(
              id,
              checked,
              items.some(({ hasAffectingRule }) => hasAffectingRule),
            )
          }
          dataAid="all_checkbox"
        />
      </Box>
    )}
    {items.map(({ id: itemId, translations, selected, hasAffectingRule }) => (
      <Box key={itemId} py="12px">
        <BigidCheckbox
          name={itemId?.toString()}
          label={translations[locale]?.label}
          checked={selected as boolean}
          onChange={(_, checked) => onChange(id, itemId, checked, hasAffectingRule)}
          dataAid={`section-${id}-item-${itemId}`}
        />
      </Box>
    ))}
  </FormGroup>
);
