import React, { FC } from 'react';
import { Box, RadioGroup, Stack } from '@mui/material';
import { BigidBody1, BigidRadio } from '@bigid-ui/components';

import { PreferenceItemType } from '../../../types/common';
import { e2e } from '../../../../utils';
import { LanguageType } from '../../../../state/languageContainer';

type OptOutFieldPropsType = {
  id: number;
  locale: LanguageType;
  items: PreferenceItemType[];
  positiveEnabled: boolean;
  negativeEnabled: boolean;
  onChange: (fieldId: number, itemId: number, checked: boolean, refresh?: boolean) => void;
  positiveLabel?: string;
  negativeLabel?: string;
};

export const OptOutField: FC<OptOutFieldPropsType> = ({
  id,
  locale,
  items,
  positiveEnabled,
  negativeEnabled,
  positiveLabel = 'Agree',
  negativeLabel = 'Disagree',
  onChange,
}) => (
  <Box pt={2}>
    <Stack justifyContent="space-between" alignItems="flex-end">
      <Stack direction="row" width="128px" alignItems="center">
        {positiveEnabled && (
          <BigidBody1 fontWeight="600" display="inline-block" flex={1} textAlign="center" size="small">
            {positiveLabel}
          </BigidBody1>
        )}
        {negativeEnabled && (
          <BigidBody1 fontWeight="600" display="inline-block" flex={1} textAlign="center" size="small">
            {negativeLabel}
          </BigidBody1>
        )}
      </Stack>
    </Stack>
    {items.map(({ id: itemId, selected, translations, hasAffectingRule }, itemIndex) => (
      <RadioGroup
        name={''}
        key={itemId}
        value={selected !== undefined ? (selected ? 'true' : 'false') : 'none'}
        sx={{
          padding: '16px 0',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <BigidBody1
          sx={{
            display: 'inline-block',
            flex: 1,
            textAlign: 'start',
          }}
        >
          {translations[locale]?.label}
        </BigidBody1>
        {positiveEnabled && (
          <BigidRadio
            aria-label={positiveLabel}
            label=""
            value={'true'}
            checked={selected === undefined ? false : undefined}
            onChange={() => onChange(id, itemId, true, hasAffectingRule)}
            sx={{
              px: negativeEnabled ? 0 : 3,
            }}
            {...e2e(`optout_radio_${itemId}`)}
          />
        )}
        {negativeEnabled && (
          <BigidRadio
            aria-label={negativeLabel}
            label=""
            value={'false'}
            checked={selected === undefined ? false : undefined}
            onChange={() => onChange(id, itemId, false, hasAffectingRule)}
            sx={{
              px: positiveEnabled ? 0 : 3,
            }}
            {...e2e(`optout_radio_${itemIndex}`)}
          />
        )}
      </RadioGroup>
    ))}
  </Box>
);
