import { createContainer } from 'unstated-next';
import { useDynamicFormReducer } from './state';
import { TenantContainer } from '../../state/tenantContainer';

const useDynamicFormContainer = () => {
  const { dispatch, state } = useDynamicFormReducer();
  const { tenant } = TenantContainer.useContainer();

  return {
    dispatch,
    state,
    config: {
      captchaOn: tenant.captchaOn,
      smsValidationOn: tenant.smsValidationOn,
    },
  };
};

export const DynamicFormContainer = createContainer(useDynamicFormContainer);
