import { pickBy } from 'lodash';
import { FieldsPost, FieldsType } from '../services';

export enum AnalyticEventType {
  CreateRequest = 'PRIVACYPORTAL_CONSUMER_APP_CREATE_REQUEST',
  ExperianStarted = 'PRIVACYPORTAL_CONSUMER_APP_EXPERIAN_STARTED',
  ExperianLeaveFromPage = 'PRIVACYPORTAL_CONSUMER_APP_EXPERIAN_LEAVE_FROM_PAGE',
  ExperianExitDialogYes = 'PRIVACYPORTAL_CONSUMER_APP_EXPERIAN_EXIT_DIALOG_YES',
  ExperianExitDialogNo = 'PRIVACYPORTAL_CONSUMER_APP_EXPERIAN_EXIT_DIALOG_NO',
}

const RequestPublicFields = [
  FieldsType.USER_COUNTRY,
  FieldsType.USER_COUNTRY_DIVISION,
  FieldsType.USER_TYPE,
  FieldsType.DELIVERY_METHOD,
  FieldsType.CITY,
];

export const getRequestPublicInformation = (fields: FieldsPost[]) =>
  fields
    .filter(field => RequestPublicFields.includes(field.id))
    .reduce((acc, field) => ({ ...acc, [field.id]: field.value }), {});

export const getRequestPublicInformationV2 = (values: Record<string, string>) =>
  pickBy(values, function (_, key) {
    return RequestPublicFields.includes(key as FieldsType);
  });

export const getPageNameByPathName = (pathName: string, tenantId: string) => {
  if (pathName.startsWith('/error')) return 'ERROR';
  if (/\/vendor-request\/\d+/.test(pathName)) return 'VENDOR_REQUEST';
  // submit request has this format /:tenantId/:publishName where publishName may be optional
  const submitRequestRegex = new RegExp(`^/${tenantId}(/[^/]+)?$`);
  if (submitRequestRegex.test(pathName)) return 'SUBMIT_REQUEST';

  return 'OTHER';
};
