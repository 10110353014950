import React, { FC, useMemo } from 'react';
import { BigidDropdown, BigidRadioGroup, BigidTextField } from '@bigid-ui/components';
import { Box } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { FieldWrapper, FileField, DateField, PhoneField, RequestTypeField } from './components';
import { FieldVariantType, NormalizedFormFieldType } from '../../../types/common';
import { cpfRegEx, emailRegEx, MAX_EMAIL_SIZE, ssnRegEx } from '../../config';
import { LanguageContainer } from '../../../../state/languageContainer';

type TemplateFieldPropsType = {
  control: Control<any>;
  formField: NormalizedFormFieldType;
  onChange?: () => void;
};

export const TemplateField: FC<TemplateFieldPropsType> = ({ control, formField, onChange }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    shouldUnregister: true,
    name: formField.name,
    control,
    defaultValue: formField.defaultValue,
    rules: !formField.hidden // skipping validation for hidden fields
      ? {
          required: formField.required,
          validate: {
            invalidPhone: value => {
              if (formField.validation === 'phone' && value && !formField.readonly) {
                const phoneUtil = PhoneNumberUtil.getInstance();
                try {
                  return phoneUtil.isValidNumber(phoneUtil.parse(value));
                } catch {
                  return false;
                }
              }
            },
            invalidEmail: value => {
              if (formField.validation === 'email' && value && !formField.readonly) {
                return emailRegEx.test(value) && value.toString().length < MAX_EMAIL_SIZE;
              }
            },
            invalidDate: value => {
              if (formField.type === 'DATE' && value && !formField.readonly) {
                return value !== 'Invalid Date';
              }
            },
            invalidNationalId: value => {
              if (formField.validation === 'cpf' && value && !formField.readonly) {
                return cpfRegEx.test(value);
              }
              if (formField.validation === 'ssn' && value && !formField.readonly) {
                return ssnRegEx.test(value);
              }
            },
          },
        }
      : {},
  });
  const { BigIdMeTranslate } = LanguageContainer.useContainer();

  const errorMessage = useMemo(() => {
    switch (error?.type) {
      case 'required':
        return `${formField.label} ${BigIdMeTranslate('consumer_web_validation_empty')}`;
      case 'invalidPhone':
        return `${formField.label} ${BigIdMeTranslate('consumer_web_validation_incorrect_phone')}`;
      case 'invalidEmail':
        return `${formField.label} ${BigIdMeTranslate('consumer_web_validation_incorrect-email')}`;
      case 'invalidNationalId':
        return `${formField.label} ${BigIdMeTranslate('consumer_web_validation_incorrect-national-id')}`;
      case 'invalidDate':
        return `${formField.label} ${BigIdMeTranslate('consumer_web_validation_incorrect-national-id')}`;
      default:
        break;
    }
  }, [error?.type, BigIdMeTranslate]);

  const isSingleSelectView = [
    FieldVariantType.SINGLE_SELECTION,
    FieldVariantType.COUNTRY,
    FieldVariantType.COUNTRY_DIVISION,
    FieldVariantType.USER_TYPE,
  ].includes(formField.type);

  return isSingleSelectView ? (
    <FieldWrapper
      labelFor={formField.name}
      label={formField.label}
      required={formField.required}
      errorMessage={errorMessage || formField.errorMessage}
      isError={!!(errorMessage || formField.errorMessage)}
      tooltip={formField.tooltip}
    >
      <BigidDropdown
        size="large"
        displayLimit={200}
        isSearchable={formField.options.length > 25}
        onSelect={([{ value }]) => {
          field.onChange(value);
          onChange?.();
        }}
        options={formField.options}
        value={formField.options.filter(option => option.value === field.value)}
        placeholder={formField.placeholder}
        isDisabled={formField.readonly}
        isError={!!(errorMessage || formField.errorMessage)}
        dataAid={`${field.name}-field`}
      />
    </FieldWrapper>
  ) : formField.type === FieldVariantType.REQUESTOR_TYPE ? (
    <FieldWrapper
      label={formField.label}
      required={formField.required}
      errorMessage={errorMessage || formField.errorMessage}
      isError={!!(errorMessage || formField.errorMessage)}
      tooltip={formField.tooltip}
    >
      <RequestTypeField
        onSelect={value => {
          field.onChange(value);
          onChange?.();
        }}
        options={formField.options}
        value={field.value}
        isDisabled={formField.readonly}
      />
    </FieldWrapper>
  ) : formField.type === FieldVariantType.REQUEST_TYPE ? (
    <RequestTypeField
      onSelect={value => {
        field.onChange(value);
        onChange?.();
      }}
      options={formField.options}
      value={field.value}
      isDisabled={formField.readonly}
    />
  ) : formField.type === FieldVariantType.RADIO ? (
    <FieldWrapper label={formField.label} required={formField.required} tooltip={formField.tooltip}>
      <BigidRadioGroup
        name={field.name}
        mainLabel={formField.label}
        horizontal
        onChange={value => {
          field.onChange(value);
          onChange?.();
        }}
        value={field.value}
        options={formField.options.map(({ value, displayValue }) => ({
          value,
          label: displayValue,
          disabled: formField.readonly,
          dataAid: `${field.name}-${value}-radio`,
        }))}
      />
    </FieldWrapper>
  ) : formField.type === FieldVariantType.DATE ? (
    <FieldWrapper
      label={formField.label}
      labelFor={field.name}
      required={formField.required}
      errorMessage={errorMessage}
      isError={!!errorMessage}
      tooltip={formField.tooltip}
    >
      <Box
        sx={{
          '& > div': { width: '100%' },
        }}
      >
        <DateField
          id={field.name}
          dateFormat={formField.dateFormat}
          value={field.value || null}
          onChange={field.onChange}
          isDisabled={formField.readonly}
          isError={!!errorMessage}
        />
      </Box>
    </FieldWrapper>
  ) : formField.type === FieldVariantType.PHONE ? (
    <FieldWrapper
      label={formField.label}
      labelFor={formField.name}
      required={formField.required}
      errorMessage={errorMessage}
      isError={!!errorMessage}
      tooltip={formField.tooltip}
    >
      <PhoneField
        id={formField.name}
        defaultValue={formField.defaultValue}
        error={errorMessage}
        isDisabled={formField.readonly}
        onChange={value => {
          field.onChange(value.phone);
        }}
        onBlur={field.onBlur}
        placeholder={formField.placeholder}
        dataAid={`${field.name}-field`}
      />
    </FieldWrapper>
  ) : formField.type === FieldVariantType.USER_PHOTO || formField.type === FieldVariantType.FILE ? (
    <FieldWrapper
      label={formField.label}
      labelFor={field.name}
      required={formField.required}
      errorMessage={errorMessage}
      isError={!!errorMessage}
      tooltip={formField.tooltip}
      description={formField.description}
    >
      <FileField id={field.name} onChange={field.onChange} />
    </FieldWrapper>
  ) : (
    <FieldWrapper
      label={formField.label}
      labelFor={formField.name}
      required={formField.required}
      tooltip={formField.tooltip}
      errorMessage={errorMessage}
      isError={!!errorMessage}
    >
      <BigidTextField
        id={field.name}
        placeholder={formField.placeholder}
        isError={!!errorMessage}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        required={formField.required}
        size="large"
        multiline={formField.type === FieldVariantType.MULTI_LINE}
        rows={4}
        disabled={formField.readonly}
        dataAid={`${field.name}-field`}
      />
    </FieldWrapper>
  );
};
