import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    alignItems: 'center',
    height: 186,
    width: 200,
    margin: 8,
    display: 'flex',
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    borderRadius: 12,
    backgroundColor: '#fff',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: 288,
    },

    [theme.breakpoints.up('sm')]: {
      '&:hover, &:focus-within, &.hover-preview': {
        border: `1px solid ${theme.palette.bigidMe?.primary}`,
        fontSize: '0.8rem',
        '& > div:first-child': {
          display: 'none',
        },
        '& > div:last-child': {
          display: 'flex',
        },
      },
    },
  },
  facade: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'flex-start',
    padding: 24,
    gap: 8,

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      height: 85,
      boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    },
  },
  icon: {
    [theme.breakpoints.down('sm')]: {
      width: 56,
      height: 56,
      flexShrink: 0,

      '& > svg': {
        width: '100%',
        height: '100%',
      },
    },
  },
  onhover: {
    display: 'none',
    height: '100%',
    padding: 15,
    background: 'white',
    borderRadius: 12,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  expanded: {
    display: 'block',
  },
  tooltip: {
    marginBottom: 10,
    overflow: 'auto',
  },
  text: {
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginTop: 0,
      marginLeft: 12,
      fontSize: '1rem',
      fontWeight: 600,
      marginRight: 8,
    },
  },
  expandIcon: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: 15,
    transform: 'translateY(-50%)',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  expandIconActive: {
    transform: 'translateY(-50%) rotate(180deg)',
  },
  selectButtonContainer: {
    width: '100%',
    justifyContent: 'end',
    display: 'flex',
  },
  selectButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  selected: {
    border: `1px solid ${theme.palette.bigidMe?.primary}`,
  },
}));
