import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { BigidMeModal } from '../../../../components/BigidMeModal';
import { DynamicFormContainer } from '../../DynamicFormContainer';
import { PhoneVerification } from '../../../../screens/PhoneVerification/PhoneVerification';
import { DynamicFormReducerActionType } from '../../state';
import { PHONE_FIELD_NAME, MOBILE_PHONE_NUMBER } from '../../config';
import { useFormValuesUpdate } from '../../hooks/useDynamicForm';

export const PhoneVerificationDialog = () => {
  const { publishName: site } = useParams();
  const {
    dispatch,
    state: { verifyPhoneDialogOpened, forms },
  } = DynamicFormContainer.useContainer();

  const { submitData } = useFormValuesUpdate(site);

  const handleCloseDialog = useCallback(() => {
    dispatch({
      type: DynamicFormReducerActionType.SET_VERIFY_PHONE_DIALOG_OPENED,
      payload: false,
    });
  }, [dispatch]);

  const handleSuccess = useCallback(
    (validationId?: string) => {
      handleCloseDialog();
      submitData({ validationId });
    },
    [handleCloseDialog, submitData],
  );

  const phoneNumberToVerify = forms?.[MOBILE_PHONE_NUMBER] || forms?.[PHONE_FIELD_NAME];

  return verifyPhoneDialogOpened && phoneNumberToVerify ? (
    <BigidMeModal open onClose={handleCloseDialog}>
      <PhoneVerification phone={phoneNumberToVerify} onPinVerificationSuccess={handleSuccess} />
    </BigidMeModal>
  ) : null;
};
