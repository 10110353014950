import { FormFieldType, FormSectionType, FormSectionVariantType } from '../../types/common';

export const normalizeSections = (sections: FormSectionType[]) =>
  sections.reduce(
    (acc, section) => ({
      ...acc,
      [section.type]: section,
      ...(section.sections?.length
        ? section.sections.reduce(
            (acc2, subSection) => ({
              ...acc2,
              [subSection.type]: subSection,
            }),
            {} as Record<FormSectionVariantType, FormSectionType>,
          )
        : {}),
    }),
    {} as Record<FormSectionVariantType, FormSectionType>,
  );

// add sub section level
export const getFormsDefaultFieldsValues = (sections: FormSectionType[]) =>
  sections.reduce((acc, section) => {
    return {
      ...acc,
      ...getDefaultFieldsValues(section.fields),
      ...(section.sections?.length
        ? section.sections.reduce((acc, section) => {
            return {
              ...acc,
              ...getDefaultFieldsValues(section.fields),
            };
          }, {} as Record<string, string>)
        : {}),
    };
  }, {} as Record<string, string>);

export const updateFormsDefaultFieldsValues = (
  prevValues: Record<string, string>,
  sections: FormSectionType[],
  keepValuesSections?: FormSectionVariantType[],
): Record<string, string> =>
  sections.reduce(
    (acc, section) => ({
      ...acc,
      ...getDefaultFieldsValues(section.fields, keepValuesSections?.includes(section.type) ? prevValues : {}),
      ...(section.sections?.length
        ? section.sections.reduce((acc, section) => {
            return {
              ...acc,
              ...getDefaultFieldsValues(section.fields, keepValuesSections?.includes(section.type) ? prevValues : {}),
            };
          }, {} as Record<string, string>)
        : {}),
      ...(!keepValuesSections ? prevValues : {}),
    }),
    {} as Record<string, string>,
  );

export const getFormsFieldMap = (sections: FormSectionType[]) =>
  sections.reduce(
    (acc, section) => ({
      ...acc,
      ...getFieldMap(section.fields),
      ...(section.sections?.length
        ? section.sections.reduce(
            (acc, section) => ({
              ...acc,
              ...getFieldMap(section.fields),
            }),
            {} as Record<string, string>,
          )
        : {}),
    }),
    {} as Record<string, string>,
  );

export const getDefaultFieldsValues = (fields: FormFieldType[], prevValues?: Record<string, string>) =>
  fields
    .filter(field => field.enabled)
    .filter(field => field.type !== 'PARAGRAPH')
    .reduce(
      (acc, field) => ({ ...acc, [field.name]: prevValues?.[field.name] || getFieldDefaultValue(field) }),
      {} as Record<string, string>,
    );

const getFieldDefaultValue = (field: FormFieldType) =>
  !!field.options?.length ? field.options.find(({ useAsDefault }) => useAsDefault)?.value || '' : '';

export const getFieldMap = (fields: FormFieldType[]) =>
  fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.uuid,
    }),
    {} as Record<string, string>,
  );
