import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  titleWrapper: {
    margin: '55px auto',
    textAlign: 'center',
    padding: '0 55px',
    maxWidth: 1200,
    [theme.breakpoints.down('sm')]: {
      margin: '24px 24px 0 24px',
      padding: 0,
    },
  },
  title: {
    fontSize: '1.375rem',
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '20px',
      fontWeight: 'bold',
    },
  },
  subTitle: {
    fontSize: '1.25rem',

    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      fontSize: '0.875rem',
      lineHeight: '20px',
      paddingBottom: '16px',
    },
  },

  description: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 300,
    overflow: 'auto',
    paddingBottom: 16,

    '& p:first-child': {
      margin: 0,
    },

    '& table, th, td': {
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: 8,
      textAlign: 'center',
      wordWrap: 'break-word',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: '20px',
    },
  },
}));
