import { DynamicFormContainer } from '../DynamicFormContainer';
import { DynamicFormReducerActionType } from '../state/actions';

export const useStepper = () => {
  const { dispatch, state } = DynamicFormContainer.useContainer();

  const handleNext = () => {
    dispatch({
      type: DynamicFormReducerActionType.SET_NEXT_STEP,
    });
  };

  const handleBack = () => {
    dispatch({
      type: DynamicFormReducerActionType.SET_BACK_STEP,
    });
  };

  const handleReset = () => {
    dispatch({
      type: DynamicFormReducerActionType.SET_ACTIVE_STEP,
      payload: 0,
    });
  };

  const handleSetActiveStep = (index: number) => {
    dispatch({
      type: DynamicFormReducerActionType.SET_ACTIVE_STEP,
      payload: index,
    });
  };

  return { activeStep: state.activeStep, handleNext, handleReset, handleBack, handleSetActiveStep };
};
