export const MAX_EMAIL_SIZE = 254;
export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const cpfRegEx =
  // eslint-disable-next-line es/no-regexp-lookbehind-assertions
  /(?<!\-|\.)\b\d{3}(?:(?:\.\d{3}){2}\-|\d{6}[ \t\-]?)\d{2}\b(?<!000\.?000\.?000[ \t\-]?00|111\.?111\.?111[ \t\-]?11|222\.?222\.?222[ \t\-]?22|333\.?333\.?333[ \t\-]?33|444\.?444\.?444[ \t\-]?44|555\.?555\.?555[ \t\-]?55|666\.?666\.?666[ \t\-]?66|777\.?777\.?777[ \t\-]?77|888\.?888\.?888[ \t\-]?88|999\.?999\.?999[ \t\-]?99)(?!\-|\.\d)/;

export const ssnRegEx =
  // eslint-disable-next-line es/no-regexp-lookbehind-assertions
  /(?<![\-\$\.@])(?!(\d)\1{2}[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]\1{2}[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]\1{4})\b(?:0(?:0[1-9]|[1-9]\d)|[1-578]\d{2}|6(?:[0-57-9]\d|6[0-57-9]))([ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0])(?:0[1-9]|[1-9]\d)\2(?:0(?:0(?:0[1-9]|[1-9]\d)|[1-9]\d{2})|[1-9]\d{3})\b(?![\-\u2010\u2011\u2012\u2013\u2014\u2015@$]|\.\d)(?<!(?:123[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]45[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]6789)|987[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]65[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]4321)/;

export const PHONE_FIELD_NAME = 'userPhone';
export const MOBILE_PHONE_NUMBER = 'mobilePhoneNumber';
export const REQUESTOR_TYPE = 'requestorType';
