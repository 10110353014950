import React, { FC, useState } from 'react';
import { FormControl } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import {
  pt,
  enUS,
  ar,
  fr,
  it,
  de,
  el,
  es,
  et,
  frCA,
  he,
  hi,
  cs,
  da,
  fi,
  ja,
  ko,
  ms,
  nl,
  nb,
  pl,
  sk,
  sv,
  th,
  tr,
  vi,
  zhCN,
} from 'date-fns/locale';
import BigidDatePicker from '@bigid-ui/components/lib/BigidDatePicker/BigidDatePicker';

import { DateFormats } from '../../../../../../services';
import { LanguageContainer, LanguageType } from '../../../../../../state/languageContainer';
import { toUTCDate } from '../../../../../../utils/dateUtils';

const VALUE_FORMAT = 'yyyy-MM-dd';

const locales: Record<LanguageType, Locale> = {
  pt,
  en: enUS,
  ar,
  fr,
  it,
  de,
  el,
  es,
  et,
  'fr-ca': frCA,
  he,
  hi,
  cs,
  da,
  fi,
  ja,
  ko,
  ms,
  nl,
  no: nb,
  pl,
  sk,
  sv,
  th,
  tr,
  vi,
  'zh-cn': zhCN,
};

export interface DateFieldProps {
  id: string;
  onBlur?: () => void;
  onChange: (value: string) => void;
  dateFormat?: 'MM_DD_YYYY' | 'DD_MM_YYYY';
  value: string;
  placeholder?: string;
  isDisabled?: boolean;
  isError?: boolean;
}

export const DateField: FC<DateFieldProps> = ({
  id,
  onChange,
  onBlur,
  dateFormat = 'MM_DD_YYYY',
  placeholder,
  isDisabled,
  isError,
  value,
}) => {
  const { language } = LanguageContainer.useContainer();
  const [internalValue, setInternalValue] = useState<Date | null | undefined>(
    value ? toUTCDate(new Date(value)) : null,
  );

  const handleChange = (newValue: Date | null) => {
    setInternalValue(newValue);
    onBlur && onBlur();
    if (newValue) {
      onChange(
        !Number.isNaN(new Date(newValue).getTime()) ? format(new Date(newValue), VALUE_FORMAT) : newValue.toString(),
      );
    } else {
      onChange('');
    }
  };

  return (
    <FormControl
      fullWidth
      sx={
        isError
          ? {
              '& .MuiInputBase-root': {
                border: '1px solid red',
              },
              '& fieldset': {
                border: 'none',
              },
            }
          : undefined
      }
    >
      <BigidDatePicker
        inputProps={{
          id,
        }}
        value={internalValue as Date}
        size="large"
        placeholder={placeholder || DateFormats[dateFormat].toUpperCase()}
        format={DateFormats[dateFormat]}
        onChange={handleChange}
        localizationProviderProps={{
          dateAdapter: AdapterDateFns,
          adapterLocale: locales[language],
        }}
        disabled={isDisabled}
      />
    </FormControl>
  );
};
