import { useCallback, useEffect } from 'react';
import queryString from 'query-string';
import { postConsumerRequests, postFormValues } from '../../services';
import { DynamicFormContainer } from '../DynamicFormContainer';
import { DynamicFormReducerActionType } from '../state';
import { useLocation, useNavigate } from 'react-router-dom';
import { TenantContainer } from '../../../state/tenantContainer';
import { MOBILE_PHONE_NUMBER, PHONE_FIELD_NAME, REQUESTOR_TYPE } from '../config';
import {
  FormSectionType,
  FormSectionVariantType,
  RequestorTypeType,
  VerificationProviderEnum,
} from '../../types/common';
import { LanguageContainer } from '../../../state/languageContainer';
import { ErrorCodes } from '../../../constants/errorCodes';
import { RedirectUrls } from '../../../constants/routes';
import { analyticsService } from '../../../state/analyticsContainer';
import { AnalyticEventType, getRequestPublicInformationV2 } from '../../../utils';

const useExtraParams = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const hashParams = new URLSearchParams(window.location.hash.substr(1));
  const idToken = hashParams.get('id_token') || '';
  const integrationName = urlSearchParams.get('integration-name') || '';
  const token = urlSearchParams.get('token') || '';
  const employeeToken = urlSearchParams.get('employee-token') || '';

  return {
    integrationName,
    idToken,
    token,
    employeeToken,
  };
};

export const useFormValues = (site: string | number = 1) => {
  const { token, employeeToken, integrationName, idToken } = useExtraParams();
  const {
    dispatch,
    state: { submittedRequest },
  } = DynamicFormContainer.useContainer();
  const { language: locale } = LanguageContainer.useContainer();

  const isSubmitted = !!submittedRequest;

  useEffect(() => {
    if (!locale) {
      return;
    }

    const fetchData = async () => {
      try {
        dispatch({
          type: DynamicFormReducerActionType.INIT_REQUEST_START,
        });
        const data = await postFormValues({
          site,
          locale,
          token,
          employeeToken,
          integrationName,
          idToken,
          fieldValues: [],
        });
        dispatch({
          type: DynamicFormReducerActionType.INIT_REQUEST_SUCCESS,
          payload: data,
        });
      } catch (err: any) {
        dispatch({
          type: DynamicFormReducerActionType.INIT_REQUEST_FAILED,
        });
      }
    };

    !isSubmitted && fetchData();
    // resets state on locale changes
  }, [site, token, employeeToken, integrationName, idToken, locale, isSubmitted]);
};

export const useFormValuesUpdate = (site: string | number = 1) => {
  const { token, employeeToken, integrationName, idToken } = useExtraParams();
  const {
    dispatch,
    state: { fieldMap, forms, captcha, verificationContext },
    config: { smsValidationOn },
  } = DynamicFormContainer.useContainer();
  const { language: locale } = LanguageContainer.useContainer();

  const navigate = useNavigate();
  const { tenant } = TenantContainer.useContainer();

  const fetchData = async (values: Record<string, string>, keepValuesSections?: FormSectionVariantType[]) => {
    if (!fieldMap) {
      return;
    }

    const fieldValues = Object.entries({ ...forms, ...values })
      .filter(([name]) => fieldMap[name])
      .map(([name, value]) => ({
        name,
        value: value || '',
        uuid: fieldMap[name],
      }));

    const formData = {
      locale,
      site,
      integrationName,
      idToken,
      token,
      employeeToken,
      fieldValues,
    };

    try {
      dispatch({
        type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_START,
        payload: {
          values,
        },
      });
      const data = await postFormValues(formData);
      dispatch({
        type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_SUCCESS,
        payload: {
          data,
          keepValuesSections,
        },
      });
    } catch (err: any) {
      if (err?.response?.data.errorCode) {
        handleError(err.response.data.errorCode);
        return;
      }
      dispatch({
        type: DynamicFormReducerActionType.GET_REQUEST_FIELDS_FAILED,
      });
    }
  };

  const submitData = async ({
    lastFormValues,
    validationId,
  }: {
    lastFormValues?: Record<string, string>;
    validationId?: string;
  }) => {
    if (!fieldMap) {
      return;
    }

    const valuesToSubmit = { ...(forms || {}), ...(lastFormValues || {}) };
    const isAgent = valuesToSubmit[REQUESTOR_TYPE] === RequestorTypeType.AUTHORIZED_AGENT;
    const phoneFieldNameToVerify = isAgent ? MOBILE_PHONE_NUMBER : PHONE_FIELD_NAME;

    if (!validationId && smsValidationOn && valuesToSubmit[phoneFieldNameToVerify]) {
      dispatch({
        type: DynamicFormReducerActionType.SET_VERIFY_PHONE_DIALOG_OPENED,
        payload: true,
      });

      return;
    }

    const fields = Object.entries(valuesToSubmit)
      .filter(([name]) => fieldMap[name])
      .map(([name, value]) => ({
        name,
        value: value || '',
        uuid: fieldMap[name],
        ...(name === phoneFieldNameToVerify && smsValidationOn ? { validationId } : {}),
      }));

    try {
      dispatch({
        type: DynamicFormReducerActionType.SUBMIT_REQUEST_START,
      });

      const response = await postConsumerRequests(
        {
          site,
          fields,
          locale,
          token,
          employeeToken,
          integrationName,
          idToken,
        },
        captcha,
      );

      analyticsService.trackManualEvent(AnalyticEventType.CreateRequest, {
        requestId: response.id,
        language: locale,
        requestType: valuesToSubmit.requestType,
        ...getRequestPublicInformationV2(valuesToSubmit),
      });

      dispatch({
        type: DynamicFormReducerActionType.SUBMIT_REQUEST_SUCCESS,
        payload: { ...response, requestType: valuesToSubmit.requestType },
      });

      if (!response || response.emailConfirmationRequired) {
        return;
      }

      if (verificationContext?.verificationProvider === VerificationProviderEnum.EXPERIAN) {
        navigate(`/${tenant.tenantId}/${response.id}/verification/experian/${response.rc}`);
        return;
      }

      if (verificationContext?.verificationProvider === VerificationProviderEnum.LEXIS_NEXIS) {
        navigate(`/${tenant.tenantId}/${response.id}/verification/lexisnexis/${response.rc}`);
        return;
      }

      navigate(`/${tenant.tenantId}/${response.id}/status/${response.rc}?skip=true`);
    } catch (err: any) {
      if (err?.response?.data.errorCode) {
        handleError(err.response.data.errorCode, {
          id: valuesToSubmit.originalRequestId,
        });
        return;
      }
      dispatch({
        type: DynamicFormReducerActionType.SUBMIT_REQUEST_FAILED,
      });
    }
  };

  const handleError = useCallback(
    (errorCode: ErrorCodes, fields?: Record<string, unknown>) => {
      if (errorCode === ErrorCodes.DUPLICATE_REQUEST || errorCode === ErrorCodes.APPEAL_REQUEST_IS_PROGRESS) {
        const requestIdentifier = forms?.deliveryMethod === 'EMAIL' ? forms?.userEmail : forms?.userPhone;
        if (requestIdentifier) {
          navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.DUPLICATE_REQUEST}/${requestIdentifier}`);
        } else {
          navigate(
            `/${tenant.tenantId}${RedirectUrls.REQUEST_ERROR}/${errorCode}${
              fields ? `?${queryString.stringify(fields)}` : ''
            }`,
          );
        }
        return;
      }
      if (errorCode === ErrorCodes.ACTION_UNAVAILABLE) {
        navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}${RedirectUrls.ACTION_ERROR}`);
        return;
      }
      navigate(
        `/${tenant.tenantId}${RedirectUrls.REQUEST_ERROR}/${errorCode}${
          fields ? `?${queryString.stringify(fields)}` : ''
        }`,
      );
    },
    [forms, navigate, tenant.tenantId],
  );

  return { fetchData, submitData };
};

// TODO: do more abstraction
export const useStepTitles = () => {
  const {
    state: { normalizedSections, forms, activeStep },
  } = DynamicFormContainer.useContainer();

  const { language: locale } = LanguageContainer.useContainer();

  const getOptionLabel = (section: FormSectionType, fieldName: string, optionValue: string) => {
    const field = section.fields.find(({ name }) => name === fieldName);
    return field?.options.find(({ value }) => value == optionValue)?.translations[locale]?.label || '';
  };

  if (!normalizedSections) {
    return {};
  }

  const startRequestSubtitle =
    activeStep > 0 && forms?.['userCountry']
      ? [
          getOptionLabel(
            normalizedSections[FormSectionVariantType.START_REQUEST],
            'userCountry',
            forms?.['userCountry'],
          ),
          getOptionLabel(
            normalizedSections[FormSectionVariantType.START_REQUEST],
            'userCountryDivision',
            forms?.['userCountryDivision'],
          ),
          getOptionLabel(normalizedSections[FormSectionVariantType.START_REQUEST], 'userType', forms?.['userType']),
          getOptionLabel(
            normalizedSections[FormSectionVariantType.START_REQUEST],
            'requestorType',
            forms?.['requestorType'],
          ),
        ]
          .filter(Boolean)
          .join(', ')
      : '';

  const requestTypeSubtitle =
    activeStep > 1 && forms?.['requestType']
      ? getOptionLabel(normalizedSections[FormSectionVariantType.REQUEST_TYPE], 'requestType', forms?.['requestType'])
      : '';

  return {
    titles: {
      [FormSectionVariantType.START_REQUEST]: `${
        normalizedSections[FormSectionVariantType.START_REQUEST].translations[locale]?.title
      }${startRequestSubtitle ? `: ` : ''}`,
      [FormSectionVariantType.REQUEST_TYPE]: `${
        normalizedSections[FormSectionVariantType.REQUEST_TYPE].translations[locale]?.title
      }${requestTypeSubtitle ? `: ` : ''}`,
      [FormSectionVariantType.DETAILS]: normalizedSections[FormSectionVariantType.DETAILS].translations[locale]?.title,
    },
    subTitles: {
      [FormSectionVariantType.START_REQUEST]: startRequestSubtitle || '',
      [FormSectionVariantType.REQUEST_TYPE]: requestTypeSubtitle || '',
      [FormSectionVariantType.DETAILS]: '',
    },
  };
};

// TODO: not used yet
export const useVerificationProvider = () => {
  const navigate = useNavigate();
  const { tenant } = TenantContainer.useContainer();
  const {
    state: { verificationContext, submittedRequest },
  } = DynamicFormContainer.useContainer();

  const hasSubmitted = !!submittedRequest;

  useEffect(() => {
    if (!hasSubmitted || !verificationContext) {
      return;
    }

    if (verificationContext.verificationProvider === VerificationProviderEnum.EXPERIAN) {
      navigate(`/${tenant.tenantId}/${submittedRequest.id}/verification/experian/${submittedRequest.rc}`);
      return;
    }
    if (verificationContext.verificationProvider === VerificationProviderEnum.LEXIS_NEXIS) {
      navigate(`/${tenant.tenantId}/${submittedRequest.id}/verification/lexisnexis/${submittedRequest.rc}`);
      return;
    }
  }, [tenant.tenantId, navigate, verificationContext]);
};
