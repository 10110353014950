/* eslint-disable */
import React, { FC, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useInterval } from '@bigid-ui/components';
import { Box } from '@mui/material';
import { useCaptcha } from '../../hooks';

const REFRESH_CAPTCHA_TIME = 90000;

export const Captcha = () => {
  const { setCaptcha } = useCaptcha();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState('');

  useInterval(() => {
    setRefreshReCaptcha(new Date().toString());
  }, REFRESH_CAPTCHA_TIME);

  return (
    <Box my={2}>
      <GoogleReCaptcha onVerify={setCaptcha} refreshReCaptcha={refreshReCaptcha} />
    </Box>
  );
};
