import React, { FC, ReactNode, useMemo } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { StylesProvider, jssPreset } from '@mui/styles';
import { createBigidMeTheme } from '../../assets/theme';
import { LanguageContainer } from '../../state/languageContainer';
import { TenantContainer } from '../../state/tenantContainer';

export { prefixer } from 'stylis';
export { default as rtlPlugin } from 'stylis-plugin-rtl';

type BigidMeAppThemePropsType = {
  children: ReactNode;
  isMobileMode?: boolean;
};

// issue https://github.com/mui/material-ui/issues/28587
export const createConsumerEmotionCache = (isRtl: boolean) =>
  isRtl
    ? createCache({
        key: 'muirtl',
        prepend: true,
        stylisPlugins: [prefixer, rtlPlugin],
      })
    : createCache({
        key: 'mui',
        prepend: true,
        stylisPlugins: [prefixer],
      });

export const BigidMeAppTheme: FC<BigidMeAppThemePropsType> = ({ children, isMobileMode }) => {
  const { isRtl } = LanguageContainer.useContainer();
  const { tenant } = TenantContainer.useContainer();

  const cache = useMemo(() => createConsumerEmotionCache(isRtl), [isRtl]);

  // needed to support deprecated makeStyles
  const jss = useMemo(
    () =>
      isRtl
        ? create({
            plugins: [...jssPreset().plugins, rtl()],
          })
        : create({
            plugins: [...jssPreset().plugins],
          }),
    [isRtl],
  );

  const computedTheme = useMemo(
    () =>
      createBigidMeTheme({
        isRtl,
        isMobileMode,
        colors: tenant.theme,
        fontFamily: tenant.theme.font?.family,
        customFont: tenant.theme.font?.custom,
      }),
    [isRtl, isMobileMode, tenant.theme],
  );

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>
        <CssVarsProvider theme={computedTheme}>{children}</CssVarsProvider>
      </StylesProvider>
    </CacheProvider>
  );
};
