import { AxiosRequestConfig } from 'axios';
import api from '../services/apiService';
import { FormType, ManagePreferencesStatus } from './types/common';
import { LanguageType } from '../state/languageContainer';

export const postFormValues = (formData = {}) => api.post<FormType>(`/forms`, formData).then(res => res.data);

export const postConsumerRequests = (formData = {}, captchaToken?: string) => {
  const config: AxiosRequestConfig = {
    headers: {
      'x-captcha-token': captchaToken || null,
    },
  };
  return api
    .post<{
      emailConfirmationRecipient: string;
      emailConfirmationRequired: boolean;
      id: string;
      rc: string;
      requestKey: string;
    }>(`/consumer-requests`, formData, captchaToken ? config : undefined)
    .then(res => res.data);
};

export const postPreferenceForms = (
  requestId: string,
  rc: string,
  formData?: {
    uuid: string;
    value: string;
  }[],
) =>
  api
    .post<{ form: FormType; scanStatus: ManagePreferencesStatus; userLocale?: LanguageType }>(
      `/requests/${requestId}/preference-forms`,
      formData ? { fieldValues: formData } : {},
      { params: { rc } },
    )
    .then(res => ({
      data: res.data.form,
      status: res.data.scanStatus,
      userLocale: res.data.userLocale || LanguageType.EN,
    }));

export const updatePreferences = (requestId: string, rc: string, formData = {}) =>
  api.put(`/requests/${requestId}/preferences`, { sections: formData }, { params: { rc } }).then(res => res.data);

export const checkEmail = (requestId: string, rc: string) => {
  return api
    .post<{ form: FormType; scanStatus: ManagePreferencesStatus; userLocale?: LanguageType }>(
      `/requests/${requestId}/preferences/${rc}/check-email`,
    )
    .then(res => ({
      data: res.data.form,
      status: res.data.scanStatus,
      userLocale: res.data.userLocale || LanguageType.EN,
    }));
};

export const resendConfirmationEmail = (requestId: string): Promise<void> =>
  api.post(`/requests/${requestId}/resend-confirmation-email`).then(res => res.data);
