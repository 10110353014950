import React, { PropsWithChildren, useMemo } from 'react';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import '@fontsource/mulish';
import { TenantContainer } from '../state/tenantContainer';
import { createAppTheme } from './utils';

export const AppTheme = ({ children }: PropsWithChildren) => {
  const { tenant } = TenantContainer.useContainer();

  const computedTheme = useMemo(
    () =>
      createAppTheme({
        colors: tenant.theme,
        fontFamily: tenant.theme.font?.family,
        customFont: tenant.theme.font?.custom,
      }),
    [tenant.theme],
  );

  return <CssVarsProvider theme={computedTheme}>{children}</CssVarsProvider>;
};
