import { experimental_extendTheme as extendTheme, CssVarsThemeOptions } from '@mui/material/styles';
import { commonTheme } from '@bigid-ui/components';
import type {} from '@mui/material/themeCssVarsAugmentation';
import { BigidMeColorSchemesVars, BigidMeSchemeCustomVars } from '../assets/theme';
import { makeIllustrationColor } from '../utils';

export const createAppTheme = ({
  colors,
  fontFamily,
  customFont,
}: {
  colors?: BigidMeSchemeCustomVars;
  fontFamily?: string;
  customFont?: boolean;
}) => {
  return extendTheme({
    ...commonTheme,
    colorSchemes: {
      ...commonTheme.colorSchemes,
      light: {
        ...commonTheme.colorSchemes?.light,
        palette: {
          ...commonTheme.colorSchemes?.light?.palette,
          bigidMe: {
            ...BigidMeColorSchemesVars.light, // can be removed soon
            ...(colors ? { ...colors, illustrationColor: makeIllustrationColor(colors.primary) } : {}),
          },
        },
      },
    },
    typography: {
      ...commonTheme.typography,
      fontFamily: fontFamily ? fontFamily : 'Mulish, Helvetica, Arial, sans-serif',
    },
    components: {
      ...commonTheme.components,
      MuiCssBaseline: {
        styleOverrides:
          customFont && fontFamily
            ? `
        @font-face {
          font-family: ${fontFamily};
          font-weight: 300;
          src: local(${fontFamily}), local('${fontFamily}-Light'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Light.woff2') format('woff2');
        }
        @font-face {
          font-family: ${fontFamily};
          font-weight: 400;
          src: local(${fontFamily}), local('${fontFamily}-Regular'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Regular.woff2') format('woff2');
        }
        @font-face {
          font-family: ${fontFamily};
          font-weight: 500;
          src: local(${fontFamily}), local('${fontFamily}-Medium'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Medium.woff2') format('woff2');
        }
        @font-face {
          font-family: ${fontFamily};
          font-weight: 700;
          src: local(${fontFamily}), local('${fontFamily}-Bold'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Bold.woff2') format('woff2');
        }
        @font-face {
          font-family: ${fontFamily};
          font-weight: 900;
          src: local(${fontFamily}), local('${fontFamily}-Black'), url('${process.env.PUBLIC_URL}/fonts/Custom1-Black.woff2') format('woff2');
        }
          body {
            background: #f6f6f6;
          }
      `
            : `
          body {
            background: #f6f6f6;
          }`,
      },
    },
  } as CssVarsThemeOptions);
};
