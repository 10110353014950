import { BigIdMeTranslateType, LanguageType } from '../../state/languageContainer';
import { getResponseError } from '../../utils/errors';
import { FieldVariantType, NormalizedFormFieldType, FormFieldType } from '../types/common';

export const normalizeFields = ({
  field,
  locale,
  defaultValue,
  translate,
}: {
  field: FormFieldType;
  locale: LanguageType;
  defaultValue: string;
  translate: BigIdMeTranslateType; // avoid it
}): NormalizedFormFieldType => ({
  label: field.translations[locale]?.label,
  text: field.translations[locale]?.text,
  tooltip: field.translations[locale]?.tooltip,
  description: field.translations[locale]?.description,
  paragraphIconEnabled: field.paragraphIconEnabled,
  paragraphBlueBackgroundEnabled: field.paragraphBlueBackgroundEnabled,
  placeholder: field.translations[locale]?.hint,
  name: field.name,
  required: field.required,
  type: field.type,
  affectingInRule: field.affectingInRule,
  defaultValue: [
    FieldVariantType.SINGLE_SELECTION,
    FieldVariantType.COUNTRY,
    FieldVariantType.COUNTRY_DIVISION,
    FieldVariantType.USER_TYPE,
  ].includes(field.type)
    ? field.options.find(({ useAsDefault }) => useAsDefault)?.value
    : defaultValue || field.translations[locale]?.defaultValue,
  options:
    field.options?.map(({ id, value, translations }) => ({
      id: `${id}`,
      value,
      displayValue: translations[locale]?.label || '',
      description: translations[locale]?.description,
    })) || [],
  errorMessage: field.error?.code ? translate(getResponseError(field.error.code)?.text || '') : '',
  hidden: field.hidden || (field.type === FieldVariantType.REQUESTOR_TYPE && field.options?.length <= 1),
  dateFormat: field.dateFormat,
  validation: field.validation,
  readonly: field.readonly,
  masked: field.masked,
  tooltipEnabled: field.tooltipEnabled,
});
