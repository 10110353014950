export type VerificationProviderType = VerificationProviderEnum.EXPERIAN | null;

export enum VerificationProviderEnum {
  NOP = 'Nop',
  CUSTOM = 'MS',
  CATHO = 'Catho',
  EXPERIAN = 'Experian',
  LEXIS_NEXIS = 'LexisNexis',
}

export type QuestionType = {
  questionText: string;
  questionSelect: string[];
};

export type AnswersType = {
  sessionId: string;
  questionResponseCounter?: number;
  answers: string[];
};

export enum ExperianResponseStatus {
  COMPLETED = 'STATUS_COMPLETED',
  FAILED = 'STATUS_FAILED',
  IN_PROGRESS = 'STATUS_IN_PROGRESS',
  MANUAL_VERIFICATION = 'STATUS_REQUIRES_MANUAL_VERIFICATION',
}

export enum LexisNexisResponseStatus {
  PENDING = 'pending',
  PASSED = 'passed',
  FAILED = 'failed',
  ERROR = 'error',
}

export interface ExperianResponseStatusType {
  decision: ExperianResponseStatus;
  decisionText: string;
  decisionReasons: string;
  nextAction: string;
  experianResponseStatusEnum: ExperianResponseStatus;
}

export interface QuestionsResponse {
  sessionId: string;
  questions: QuestionType[];
  experianResponseStatus: ExperianResponseStatusType;
}

export interface LexisNexisChoice {
  choiceId: number;
  text: string;
}

export interface LexisNexisQuestionSingleChoice {
  choices: LexisNexisChoice[];
  helpText?: string;
  questionId: number;
  text: string;
  type: 'singlechoice';
}

export interface LexisNexisQuestionFillInTheBlank {
  choices: [] | never;
  helpText?: string;
  questionId: number;
  text: string;
  type: 'fillInTheBlank';
}

export type LexisNexisQuestion = LexisNexisQuestionSingleChoice | LexisNexisQuestionFillInTheBlank;

export interface LexisNexisQuestionsResponse {
  conversationId: string;
  questionSet: {
    questions: LexisNexisQuestion[];
  };
  transactionStatus: LexisNexisResponseStatus;
}

export type LexisNexisAnswerType = {
  questionId: number;
  choices: Array<{ choice: string | number }>;
};

export type LexisNexisAnswersType = {
  questionSetId: number;
  questions: LexisNexisAnswerType[];
};

export type LexisNexisAnswersRequestType = {
  conversationId: string;
  answers: LexisNexisAnswersType;
};
