import { useEffect } from 'react';
import { setPageTitle } from '../../../utils/helpers';
import { LanguageContainer } from '../../../state/languageContainer';
import { RequestVariantType } from '../../types/common';
import { DynamicFormContainer } from '../DynamicFormContainer';

const PAGE_TITLE = {
  [RequestVariantType.VIEW]: 'page_title_view_data_form',
  [RequestVariantType.UPDATE]: 'page_title_update_data_form',
  [RequestVariantType.DELETE]: 'page_title_delete_data_form',
  [RequestVariantType.PREFERENCES]: 'state_tabs.preferences',
  [RequestVariantType.OPT_OUT]: 'page_title_dont_share_or_sell',
  [RequestVariantType.APPEAL]: 'page_title_appeal_data_form',
};

export const useSetPageTitle = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const {
    state: { forms },
  } = DynamicFormContainer.useContainer();

  const type = forms?.requestType as RequestVariantType;

  useEffect(() => {
    if (type) {
      setPageTitle(BigIdMeTranslate(PAGE_TITLE[type]));
    } else {
      setPageTitle(BigIdMeTranslate('page_title_privacy_center'));
    }
  }, [type, BigIdMeTranslate]);
};
