import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components/lib/assets/theme/BigidColorsV2';
import { errorColor, gray, linkText, white } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& .file-drop': {
      minHeight: 160,
      minWidth: 390,
      [theme.breakpoints.down('sm')]: {
        minHeight: 'auto',
        minWidth: 'auto',
      },
    },
  },
  dropdownArea: {
    position: 'relative',
    border: `1px  dashed ${BigidColorsV2.gray[300]}`,
    borderRadius: 12,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      minHeight: 190,
    },
  },
  loadedArea: {
    padding: 24,
    fontSize: '0.8125rem',
    textAlign: 'center',
  },
  link: {
    color: linkText,
  },
  remove: {
    color: linkText,
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    verticalAlign: 'top',
    cursor: 'pointer',
  },
  subtext: {
    fontSize: '0.8125rem',
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      padding: '0 20px',
    },
  },
  filename: {
    display: 'inline-block',
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
      maxWidth: 170,
    },
  },
  uploadWrapper: {
    position: 'relative',
    borderRadius: 19,
    padding: '8px 15px',
    fontSize: '0.9375rem',
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
  },
  uploadMobileWrapper: {
    textAlign: 'center',
    cursor: 'pointer',
  },
  uploadInputWrapper: {
    position: 'relative',
  },
  uploadMobileInputWrapper: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'block',
      border: `2px solid ${gray[775]}`,
      borderRadius: 30,
      padding: 11,
      marginTop: 16,

      fontSize: '1rem',
      fontWeight: 600,
      textAlign: 'center',
    },
  },
  upload: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: '6px 0',
    display: 'inline-block',
    opacity: 0,
    cursor: 'pointer',
  },
  error: {
    color: errorColor,
  },
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    padding: 30,
    backgroundColor: gray[25],
    borderRadius: 19,
    fontSize: '0.9375rem',
    fontWeight: 600,
  },
  crossMobile: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 9,
    width: 15,
  },
  replace: {
    color: gray[775],
    textDecoration: 'underline',
    textTransform: 'capitalize',
  },
  success: {
    width: 19,
    verticalAlign: 'super',
  },
  bgVelum: {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.47)',
  },
  idTypeVelum: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: 15,

    borderRadius: '12px 12px 0 0',
    boxShadow: '0 -4px 14px 0 rgba(0,0,0,0.1)',
    backgroundColor: white,
  },
  item: {
    position: 'relative',
    padding: 15,
  },
  isHidden: {
    display: 'none',
  },
  iconId: {
    marginRight: 5,
    verticalAlign: 'bottom',
  },
}));
