import { useCallback } from 'react';
import { DynamicFormContainer } from '../DynamicFormContainer';
import { DynamicFormReducerActionType } from '../state/actions';

export const useCaptcha = () => {
  const {
    dispatch,
    state: { captcha },
  } = DynamicFormContainer.useContainer();

  const setCaptcha = useCallback(
    (payload: string) => {
      dispatch({
        type: DynamicFormReducerActionType.SET_CAPTCHA,
        payload,
      });
    },
    [dispatch],
  );

  const resetCaptcha = useCallback(() => {
    dispatch({
      type: DynamicFormReducerActionType.SET_CAPTCHA,
      payload: '',
    });
  }, [dispatch]);

  return { captcha, setCaptcha, resetCaptcha };
};
