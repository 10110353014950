import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { StyledFieldWrapper } from './FieldWrapperStyles';
import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { BigidPolicyIcon } from '@bigid-ui/icons';

type FieldWrapperPropsType = {
  children: ReactNode;
  required?: boolean;
  label?: string;
  errorMessage?: string;
  isError?: boolean;
  tooltip?: string;
  description?: string;
  labelFor?: string;
};

export const FieldWrapper: FC<FieldWrapperPropsType> = ({
  children,
  required,
  label,
  errorMessage,
  isError,
  tooltip,
  description,
  labelFor,
}) => (
  <Box>
    {label && (
      <StyledFieldWrapper.Label htmlFor={labelFor}>
        {label}
        {required && <StyledFieldWrapper.Asterisk aria-hidden="true">*</StyledFieldWrapper.Asterisk>}
      </StyledFieldWrapper.Label>
    )}
    {description && (
      <BigidBody1 size="small" mt={1} data-aid={`${labelFor}-description`}>
        {description}
      </BigidBody1>
    )}
    <StyledFieldWrapper.FieldWrapper>{children}</StyledFieldWrapper.FieldWrapper>
    {isError && (
      <Box
        id={`${labelFor}-error`}
        fontSize="12px"
        color={BigidColorsV2.red[600]}
        mt="4px"
        data-aid={`${labelFor}-error`}
      >
        {errorMessage}
      </Box>
    )}
    {tooltip && (
      <Box mt={1} display="flex" alignItems="center" gap={0.5} data-aid={`${labelFor}-tooltip`}>
        <Box flexShrink={0}>
          <BigidPolicyIcon size="small" />
        </Box>
        <BigidBody1 size="small">{tooltip}</BigidBody1>
      </Box>
    )}
  </Box>
);
