import { AxiosRequestConfig } from 'axios';
import {
  FieldsPost,
  RequestStatus,
  RequestCountriesType,
  UploadPhotoType,
  UserFilesType,
  ManagePreferencesType,
  ManagePreferencesStepType,
  SubmitRequestResponse,
  MessageDto,
  FlowsResponse,
  RequestorTypeType,
  AuthorizedAgentFieldsPost,
} from './types';
import { api } from '../apiService';
import {
  EditDataResponseType,
  EditDataRequestFieldType,
  EditDataResponseFieldType,
} from '../../screens/EditData/EditData';
import { getTenantFromLocation } from '../../utils/commonUtils';

const apiBaseUrl = process.env.REACT_APP_WORKFLOW_API_BASE_URL;

const getRequestFormByPublishName = ({
  publishName = '',
  language = '',
  token,
  employeeToken,
  integrationName,
  idToken,
}: {
  publishName?: string;
  language: string;
  token?: string;
  employeeToken?: string;
  integrationName?: string;
  idToken?: string;
}): Promise<FlowsResponse> => {
  const url = `/request-submit-form/publishName${publishName ? `/${publishName}` : ''}?locale=${language}`;
  return api
    .get(url, {
      params: { token, 'employee-token': employeeToken, 'integration-name': integrationName, id_token: idToken },
    })
    .then(res => res.data || []);
};

const submitRequest = ({
  definitionId,
  fields,
  userLocale,
  captchaToken,
  userFiles,
  publishName,
  requestorType,
  requestorFields,
  requestorFiles,
  submitFormId,
}: {
  definitionId: string;
  fields: FieldsPost[];
  userLocale: string;
  captchaToken: string | null;
  userFiles: UserFilesType[];
  publishName: string;
  requestorType?: RequestorTypeType;
  requestorFields?: AuthorizedAgentFieldsPost[];
  requestorFiles?: UserFilesType[];
  submitFormId: number;
}): Promise<SubmitRequestResponse> => {
  const config: AxiosRequestConfig = {
    headers: {
      'x-captcha-token': captchaToken,
    },
  };

  return api
    .post(
      `/requests`,
      {
        definitionId,
        publishName,
        fields,
        userLocale,
        userFiles,
        requestorType,
        requestorFields,
        requestorFiles,
        submitFormId,
      },
      config,
    )
    .then(res => res.data);
};

const confirmRequest = async (requestId: string, confirmationId: string): Promise<void> =>
  api.post(`/requests/${requestId}/request-confirmations/${confirmationId}`).then(res => res.data);

const resendConfirmationEmail = (requestId: string): Promise<void> =>
  api.post(`/requests/${requestId}/resend-confirmation-email`).then(res => res.data);

const getRequestStatus = (requestId: string, rc: string): Promise<RequestStatus> =>
  api.get(`/requests/${requestId}/user-status?rc=${rc}`).then(res => res.data);

const getRequestMessages = (requestId: string, ms: string): Promise<MessageDto[]> =>
  api.get(`/requests/${requestId}/messages?ms=${ms}`).then(res => res.data.data);
export const sendMessage = async (
  requestId: string,
  {
    title,
    subject,
    content,
    files,
  }: { title: string; subject: string; content: string; files: { fileUploadId: string }[] },
  ms: string,
): Promise<void> =>
  await api
    .post(`/requests/${requestId}/messages?ms=${ms}`, { title, subject, content, files })
    .then(resolve => resolve.data);

const getRequestMessageFile = (fileId: number, fileName: string, requestId: string, ms: string): Promise<void> =>
  api
    .get(`/requests/${requestId}/messages/files/${fileId}?ms=${ms}`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });

const getSelectCounties = (locale: string): Promise<RequestCountriesType> =>
  api
    .get(`/countries`, {
      params: {
        locale,
      },
    })
    .then(res => res.data);

const sendRequestVerificationCode = (requestId: string, rc: string): Promise<void> =>
  api.post(`/requests/${requestId}/verification-code?rc=${rc}`).then(res => res.data);

const validatePhone = (phone: string, userLocale: string): Promise<{ validationId: string }> =>
  api.post<{ validationId: string }>(`/validate-phone`, { phone, userLocale }).then(res => res.data);

const validateSMSCode = (code: string, validationId: string): Promise<{ validated: boolean }> =>
  api.post(`/validate-sms-code`, { code, validationId }).then(res => res.data);

const downloadReport = (requestId: string, code: string, rc: string) => {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = `${apiBaseUrl}/${getTenantFromLocation()}/requests/${requestId}/report?code=${code}&rc=${rc}`;
  document.body.appendChild(iframe);
};

const editData = (
  requestId: string,
  rc: string,
  code?: string,
): Promise<EditDataResponseType<EditDataRequestFieldType[]>> => {
  return api.get(`/requests/${requestId}/editable-fields?rc=${rc}${code ? `&code=${code}` : ''}`).then(res => res.data);
};

const editDataSubmit = (
  requestId: string,
  rc: string,
  code: string,
  fields: EditDataResponseFieldType[],
  userComment = '',
): Promise<void> =>
  api
    .post(`/requests/${requestId}/editable-fields?rc=${rc}${code ? `&code=${code}` : ''}`, { fields, userComment })
    .then(res => res.data);

const uploadVerificationPhoto = (formData: FormData): Promise<string> =>
  api.post<UploadPhotoType>(`/file`, formData).then(res => res.data.uploadId);

type ManagePreferencesRequestType = {
  requestId: string;
  rc: string;
};

const fetchPreferences = (requestId: string, rc: string): Promise<ManagePreferencesType> => {
  return api.get(`/requests/${requestId}/preferences`, { params: { rc } }).then(res => res.data);
};

const getVerificationProviderByRequestId = async (requestId: string): Promise<string> => {
  return api.get(`/requests/${requestId}/verification-provider`).then(res => res.data);
};

const getFormIdByPublishName = (publishName: string): Promise<number> => {
  return api.get(`/forms/publishName/${publishName}/formId`).then(res => res.data);
};

type UpdatePreferencesType = ManagePreferencesRequestType & {
  steps: ManagePreferencesStepType[];
  selectedReason?: string;
};

const updatePreferences = (props: UpdatePreferencesType): Promise<void> => {
  const { steps, requestId, selectedReason, rc } = props;

  return api
    .put(`/requests/${requestId}/preferences`, { sections: steps[0].sections, selectedReason }, { params: { rc } })
    .then(res => res.data);
};

const checkEmail = (props: ManagePreferencesRequestType): Promise<ManagePreferencesType> => {
  const { requestId, rc } = props;

  return api.post(`/requests/${requestId}/preferences/${rc}/check-email`).then(res => res.data);
};

const sendOptFieldVerifications = (formData: {
  fieldValue: string;
  fieldName: string;
  data: Record<string, string>;
  siteName: string;
  locale: string;
}) =>
  api
    .post<{
      token: string;
      verificationId: string;
      status: 'IN_PROGRESS' | 'FAILED';
    }>(`/otp-field-verifications`, formData)
    .then(res => res.data);

const checkOptFieldVerifications = (verificationId: string) =>
  api
    .get<{
      verificationId: string;
      status: 'IN_PROGRESS' | 'FAILED' | 'SUCCEEDED';
      resolution: string;
    }>(`/otp-field-verifications/${verificationId}`)
    .then(res => res.data);

export const requestService = {
  getFormIdByPublishName,
  getVerificationProviderByRequestId,
  getRequestFormByPublishName,
  submitRequest,
  confirmRequest,
  getRequestStatus,
  getSelectCounties,
  sendRequestVerificationCode,
  validateSMSCode,
  downloadReport,
  editData,
  validatePhone,
  editDataSubmit,
  resendConfirmationEmail,
  uploadVerificationPhoto,
  fetchPreferences,
  updatePreferences,
  checkEmail,
  getRequestMessages,
  getRequestMessageFile,
  sendMessage,
  sendOptFieldVerifications,
  checkOptFieldVerifications,
};

export enum ConfirmationResult {
  Waiting,
  Confirmed,
  AlreadyUsedOrExpired,
  Error,
}
